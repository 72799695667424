define("pesquisa-talentrh/models/quiz", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "ember-data-copyable"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    validity: (0, _emberCpValidations.validator)('date', {
      onOrAfter: 'now',
      precision: 'day',
      message: 'A data não pode ser anterior a hoje'
    })
  });
  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    // Attributes
    anonymous: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    answers: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    answerThroughLink: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    color: (0, _attr.default)('string', {
      defaultValue: 'blue'
    }),
    createdAt: (0, _attr.default)('date'),
    public: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    sections: (0, _attr.default)('raw', {
      defaultValue() {
        return [{
          questions: [{
            title: null,
            type: 'radio',
            required: false,
            order: 0,
            alternatives: [{
              label: null,
              order: 0
            }],
            level: {
              label: '5',
              value: 5
            },
            simbol: 'stars',
            label1: null,
            label2: null,
            likertColumns: [{
              label: 'Coluna 1'
            }, {
              label: 'Coluna 2'
            }],
            likertRows: [{
              label: 'Linha 1'
            }, {
              label: 'Linha 2'
            }]
          }]
        }];
      }
    }),
    sentQuiz: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    status: (0, _attr.default)('string', {
      defaultValue: 'R'
    }),
    title: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    validity: (0, _attr.default)('date'),
    allowMultipleAnswers: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    quizType: (0, _attr.default)('string', {
      defaultValue: 'search'
    }),
    daysToAnswer: (0, _attr.default)('number'),
    causes: (0, _attr.default)('raw', {
      defaultValue: () => []
    }),
    autoSend: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    sendType: (0, _attr.default)('raw', {
      defaultValue() {
        return [];
      }
    }),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});