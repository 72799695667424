define("pesquisa-talentrh/pods/components/chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y/BwHVZB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex align-items-center mb-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-100 ml-3\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[14,1],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm p-1\"],[10,\"title\",\"Barra\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showBarChart\"],null]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-chart-bar fa-lg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm p-1\"],[10,\"title\",\"Pizza\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showPieChart\"],null]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-chart-pie fa-lg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[5,\"chart/canvas\",[],[[\"@type\",\"@data\",\"@options\"],[[22,\"chartType\"],[22,\"data\"],[22,\"chartOptions\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/components/chart/template.hbs"
    }
  });
  _exports.default = _default;
});