define("pesquisa-talentrh/pods/home/dashboard/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['row'],
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    data: null,
    color: '#6475FD',
    init() {
      this._super(...arguments);
      this.chartOptions = {
        responsive: true,
        // Instruct chartJS to respond nicely
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 15
          }
        }
      };
    },
    getData: (0, _emberConcurrency.task)(function* () {
      let data = yield this.ajax.request('dashboard');
      Ember.set(this, 'data', data);
    }).on('didInsertElement'),
    //Variavel computada que pega a quantidade de cada status dos questionários
    //e retorna o objeto usado para gerar o grafico de Informaçõe de Status
    statusData: Ember.computed('data.status', function () {
      return {
        datasets: [{
          data: [Ember.get(this, 'data.status.open'), Ember.get(this, 'data.status.draft'), Ember.get(this, 'data.status.closed')],
          backgroundColor: ['#28a745', '#ffc107', '#dc3545']
        }],
        labels: ['Abertos', 'Rascunhos', 'Fechados']
      };
    }),
    //Variavel usuada para gerar o gráfico de total de respostas
    statusAnswersCompleted: Ember.computed('data.sended', function () {
      return {
        datasets: [{
          data: [Ember.get(this, 'data.sended.answered'), Ember.get(this, 'data.sended.notAnswered')],
          backgroundColor: ['#6475FD', '#EBECF2']
        }],
        labels: ['Respondidos', 'Não Respondidos']
      };
    }),
    //Metodo usado para calular a porcentagem de questionários
    //respondidos e finalizados
    percentConclusion: Ember.computed('data.sended', function () {
      const answered = this.get('data.sended.answered');
      const all = this.get('data.sended.notAnswered') + answered;
      const percent = answered * 100 / all;
      if (percent > 0) return Math.floor(percent);
      return 0;
    }),
    //Variavel usada para calulcar o tempo médio de cada resposta
    time: Ember.computed('data.averageConclusionTime', function () {
      if (!this.data) {
        return;
      }
      let str = '';
      let duration = _moment.default.duration(this.data.averageConclusionTime, 'seconds');
      if (duration.get('H')) {
        str = `${duration.get('H')}h `;
      }
      if (duration.get('m')) {
        str += `${duration.get('m')}m `;
      }
      return `${str}${duration.get('s')}s`;
    })
  });
  _exports.default = _default;
});