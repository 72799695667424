define("pesquisa-talentrh/pods/answer-view/controller", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    noAnswer: Ember.String.htmlSafe('<i>Não respondido</i>'),
    quiz: Ember.computed.alias('model.quiz'),
    quizAnswer: Ember.computed.alias('model'),
    userAvatar: Ember.computed('quizAnswer', async function () {
      const user = await Ember.get(this.quizAnswer, 'user');
      if (!user.active && this.quiz.quizType !== 'demissional') {
        return '/images/inactive-user.png';
      }
      if (!user.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      return `${_environment.default.bucketUrl}${user.avatarUrl}`;
    }),
    quizAnswerViewUserParams: Ember.computed('quizAnswer', function () {
      return {
        quiz_id: this.quiz.get('id'),
        user_id: this.quizAnswer.user.get('id'),
        userName: this.quizAnswer.user.get('firstName') + ' ' + this.quizAnswer.user.get('lastName'),
        avatarUrl: this.quizAnswer.user.get('avatarUrl'),
        quizTitle: this.quiz.get('title')
      };
    }),
    actions: {
      toggleText(section, question) {
        document.getElementById(`section${section}Question${question}`).classList.toggle('text-truncate');
      }
    }
  });
  _exports.default = _default;
});