define("pesquisa-talentrh/models/quiz-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    color: _emberData.default.attr('string', {
      defaultValue: 'blue'
    }),
    sections: _emberData.default.attr('raw', {
      defaultValue: () => [{
        questions: [{
          title: null,
          type: 'radio',
          required: false,
          order: 0,
          alternatives: [{
            label: null,
            order: 0
          }]
        }]
      }]
    }),
    quizType: _emberData.default.attr('string', {
      defaultValue: 'search'
    })
  });
  _exports.default = _default;
});