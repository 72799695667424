define("pesquisa-talentrh/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SEDXCStP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showMenu\"]]],null,{\"statements\":[[0,\"  \"],[5,\"application/navbar\",[],[[],[]]],[0,\"\\n\\n  \"],[5,\"application/sidebar\",[],[[],[]]],[0,\"\\n\\n  \"],[5,\"ui-application\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/application/template.hbs"
    }
  });
  _exports.default = _default;
});