define("pesquisa-talentrh/services/contact-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function removeAccents(txt) {
    txt = txt || '';
    return txt.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  var _default = Ember.Service.extend({
    nela: Ember.inject.service(),
    session: Ember.inject.service(),
    isListLoaded: false,
    users: Ember.A(),
    groups: Ember.A(),
    loadList() {
      Ember.set(this, 'isListLoaded', false);
      Ember.RSVP.all([Ember.get(this, 'nela').request('/chat/usergroups')]).then(response => {
        const groups = response[0].groups;
        Ember.set(this, 'groups', groups);
        return 'A lista de grupos foi carregada.';
      }).catch(() => {
        return 'Não foi possível carregar a lista de grupos';
      }).finally(() => Ember.set(this, 'isListLoaded', true));
    },
    filterUsers() {
      let filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let shouldReturnSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const regExp = new RegExp(filter.trim(), 'i');
      return this.get('users').filter(item => {
        return item.active && (item.name && item.name.match(regExp) || item.professionalEmail && item.professionalEmail.match(regExp) || item.jobFunction && item.jobFunction.match(regExp) || item.department && item.department.match(regExp) || item.professionalPhone && item.professionalPhone.match(regExp)) && (item.id !== Ember.get(this, 'session.user.id') || shouldReturnSelf);
      });
    },
    filterGroups() {
      let filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return Ember.get(this, 'groups').filter(item => {
        return item.name && removeAccents(item.name).match(new RegExp(removeAccents(filter.trim()), 'i'));
      });
    },
    getUser(id) {
      return Ember.get(this, 'users').findBy('id', parseInt(id));
    },
    getGroup(id) {
      return Ember.get(this, 'groups').findBy('id', parseInt(id));
    },
    _updateObject(current, updated) {
      var key,
        keys = Object.keys(updated);
      var n = keys.length;
      while (n--) {
        key = keys[n];
        Ember.set(current, key, updated[key]);
      }
    }
  });
  _exports.default = _default;
});