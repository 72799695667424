define("pesquisa-talentrh/pods/answer-completed/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    quiz: Ember.computed.alias('model.quizAnswer.quiz'),
    title: Ember.computed.alias('model.title'),
    subtitle: Ember.computed.alias('model.subtitle')
  });
  _exports.default = _default;
});