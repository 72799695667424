define("pesquisa-talentrh/pods/result/general/section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    showIndividualQuestions: false,
    departments: Ember.A([]),
    users: Ember.A([]),
    endpoint: Ember.computed('quiz', 'section', function () {
      let quiz = Ember.get(this, 'quiz');
      let index = Ember.get(this, 'section.index');
      return `/quizAnswerItems/${quiz.id}/chartSectionData/${index}`;
    })
  });
  _exports.default = _default;
});