define("pesquisa-talentrh/pods/components/survey-form/navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d05zQAZB",
    "block": "{\"symbols\":[\"Card\",\"section\",\"sectionIndex\",\"@sections\"],\"statements\":[[5,\"ui-card\",[[12,\"class\",\"shadow-sm\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[[12,\"id\",\"survey-navigation\"],[12,\"class\",\"px-1\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasOverflow\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",true],[10,\"class\",\"btn btn-light position-absolute rounded-0 h-100 l-0\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"moveLeft\"],null]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-left\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"button\",true],[10,\"class\",\"btn btn-light position-absolute rounded-0 h-100 r-0\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"moveRight\"],null]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-right\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"d-inline-block py-1 m-1 cursor-pointer text-truncate response-section-title\"],[11,\"id\",[29,[\"navSection\",[23,3,[]]]]],[11,\"title\",[29,[[28,\"or\",[[23,2,[\"title\"]],\"Seção sem título\"],null]]]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"selectSection\",[23,3,[]]],null]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"badge badge-primary mr-1\"],[8],[0,\"\\n          \"],[1,[28,\"sum\",[[23,3,[]],1],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[1,[28,\"or\",[[23,2,[\"title\"]],\"Seção sem título\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/components/survey-form/navigation/template.hbs"
    }
  });
  _exports.default = _default;
});