define("pesquisa-talentrh/nela-select/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/nela-select-multiple.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-select-multiple.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-select.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-select.hbs should pass TemplateLint.\n\n');
  });
});