define("pesquisa-talentrh/intro/survey/index/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    steps: [{
      element: '[data-step="survey-index-1"]',
      intro: `
        <div class="font-weight-bold text-center text-primary">Questionários</div>
        <div>É aqui que você gerencia os seus questionários e pode visualizar dados como: Status, data da última atualização, quantidade de respostas e número de pessoas para as quais os questionários foram enviados.</div>
      `
    }, {
      element: '[data-step="survey-index-2"]',
      intro: `
        <div class="font-weight-bold text-center text-primary">Resultados</div>
        <div>Essa opção te dará acesso aos resultados da sua pesquisa e também as respostas individuais.</div>
      `
    }, {
      element: '[data-step="survey-index-4"]',
      intro: `
        <div class="font-weight-bold text-center text-primary">Mais opções</div>
        <div>Aqui você pode editar, duplicar, excluir, alterar validade e Exportar resultados do seu questionário.</div>
      `
    }, {
      element: '[data-step="survey-index-5"]',
      intro: `
        <div class="font-weight-bold text-center text-primary">Acesso ao tutorial</div>
        <div>Surgiu alguma dúvida? Você pode acessar o tutorial novamente clicando aqui.</div>
      `
    }]
  };
  _exports.default = _default;
});