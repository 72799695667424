define("pesquisa-talentrh/transforms/raw", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      return serialized;
    },
    serialize(deserialized) {
      return deserialized;
    }
  });
  _exports.default = _default;
});