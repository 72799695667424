define("pesquisa-talentrh/pods/application/controller", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    menuControl: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    accessProfile: Ember.computed.alias('permission.accessProfile'),
    companyConfiguration: Ember.computed.alias('permission.companyConfiguration'),
    user: Ember.computed.alias('permission.user'),
    showMenu: Ember.computed('session.{isAuthenticated,user}', 'menuControl.show', 'permission.isLoaded', function () {
      return Ember.get(this, 'session.isAuthenticated') && Ember.get(this, 'menuControl.show') && Ember.get(this, 'permission.isLoaded') && Ember.get(this, 'session.user');
    }),
    isProductionBuild: Ember.computed(function () {
      return _environment.default.environment === 'production';
    }),
    actions: {
      logout() {
        Ember.get(this, 'session').invalidate();
      }
    }
  });
  _exports.default = _default;
});