define("pesquisa-talentrh/components/ui-tabs", ["exports", "ui-nela/components/ui-tabs"], function (_exports, _uiTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiTabs.default;
    }
  });
});