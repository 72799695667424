define("pesquisa-talentrh/services/ajax", ["exports", "ember-ajax/services/ajax", "pesquisa-talentrh/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    host: _environment.default.apiUrl.pesquisa,
    session: Ember.inject.service(),
    headers: Ember.computed('session.session.content.authenticated.token', {
      get() {
        let headers = {};
        const authToken = this.get('session.session.content.authenticated.token');
        if (authToken) {
          headers['Authorization'] = 'Bearer ' + authToken;
        }
        return headers;
      }
    })
  });
  _exports.default = _default;
});