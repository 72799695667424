define("pesquisa-talentrh/components/ui-skeleton", ["exports", "ui-nela/components/ui-skeleton"], function (_exports, _uiSkeleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiSkeleton.default;
    }
  });
});