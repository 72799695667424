define("pesquisa-talentrh/components/nela-datepicker", ["exports", "nela-datepicker/components/nela-datepicker"], function (_exports, _nelaDatepicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nelaDatepicker.default;
    }
  });
});