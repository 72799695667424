define("pesquisa-talentrh/pods/survey/index/send/link/component", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['tab-pane', 'fade'],
    toast: Ember.inject.service(),
    link: Ember.computed('quiz.id', function () {
      let url = _environment.default.appUrl['pesquisa'];
      let quiz = Ember.get(this, 'quiz.id');
      return `${url}responder/${quiz}`;
    }),
    actions: {
      copy() {
        let element = document.createElement('textarea');
        element.value = this.link;
        element.setAttribute('readonly', '');
        element.style = {
          display: 'none'
        };
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        this.toast.success('Copiado para a área de transferência');
      }
    }
  });
  _exports.default = _default;
});