define("pesquisa-talentrh/pods/survey/index/send/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    activeTab: 0,
    externalEmails: Ember.A(),
    quiz: Ember.computed.alias('model.quiz'),
    quizAnswers: Ember.computed.alias('model.quizAnswers'),
    quizType: Ember.computed.alias('model.quiz.quizType'),
    showSmsTab: Ember.computed('quizType', function () {
      return this.quizType === 'demissional';
    }),
    showSendButton: Ember.computed('activeTab', function () {
      return this.activeTab !== 2;
    }),
    sendTask: Ember.computed('activeTab', function () {
      let task;
      if (this.activeTab === 0) {
        task = this.sendSurvey;
      }
      if (this.activeTab === 3) {
        task = this.sendSmsDemissional;
      }
      return task;
    }),
    activeTabComputed: Ember.computed('activeTab', 'quiz.answerThroughLink', function () {
      if (this.quiz.answerThroughLink) {
        return 2;
      }
      return this.activeTab;
    }),
    sendSurvey: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar envio do questionário?', {
          text: 'Todos os participantes serão notificados'
        });
        if (!value) {
          return;
        }
        let users = this.quizAnswers.map(quizAnswer => {
          if (quizAnswer.id) {
            return;
          }
          return {
            id: quizAnswer.user.id
          };
        }).compact();
        if (users.length) {
          let id = this.quiz.id;
          yield this.ajax.post('quizzes/send', {
            data: {
              id,
              users
            }
          });
          this.toast.success('Questionário enviado');
          this.send('closeModal');
        } else {
          this.swal.warning('Selecione pelo menos um novo destinatário para enviar.');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    sendExternal: (0, _emberConcurrency.task)(function* () {
      let {
        value
      } = yield this.swal.confirm('Confirmar envio para os emails informados?');
      if (!value) {
        return;
      }
      if (!this.externalEmails.length) {
        return this.swal.warning('Informe pelo menos um destinatário para enviar');
      }
      try {
        yield this.ajax.post('quizzes/sendExternal', {
          data: {
            id: this.quiz.id,
            emails: this.externalEmails
          }
        });
        this.swal.success('Questionário enviado com sucesso!');
        this.externalEmails.clear();
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    sendSmsDemissional: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar envio do questionário?', {
          text: 'Todos os participantes serão notificados'
        });
        if (!value) {
          return;
        }
        let users = this.quizAnswers.map(quizAnswer => {
          if (quizAnswer.id) {
            return;
          }
          return {
            id: quizAnswer.user.id
          };
        }).compact();
        if (users.length) {
          let id = this.quiz.id;
          yield this.ajax.post('quizzes/sendDemissionalQuiz', {
            data: {
              id,
              users
            }
          });
          this.toast.success('Questionário enviado');
          this.send('closeModal');
        } else {
          this.swal.warning('Selecione pelo menos um novo destinatário para enviar.');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      closeModal() {
        this.transitionToRoute('survey');
      },
      onClickTab(index) {
        this.set('activeTab', index);
        if (this.quiz.quizType === 'demissional') {
          this.set('quizAnswers', Ember.A([]));
        }
      }
    }
  });
  _exports.default = _default;
});