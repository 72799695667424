define("pesquisa-talentrh/pods/model-quiz-demissional/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    demissionalQuizModel: Ember.computed.alias('model'),
    useQuiz: (0, _emberConcurrency.task)(function* () {
      try {
        let quiz = this.store.createRecord('quiz', {
          title: this.demissionalQuizModel.title,
          color: this.demissionalQuizModel.color,
          sections: this.demissionalQuizModel.sections,
          user: this.session.user,
          public: true,
          anonymous: false,
          quizType: 'demissional'
        });
        let {
          value
        } = yield this.swal.confirm('Deseja criar um Questionário a partir desse modelo?');
        if (!value) {
          return;
        }
        quiz = yield quiz.save();
        this.transitionToRoute('survey.edit', quiz.id);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});