define("pesquisa-talentrh/pods/survey/index/send/sms/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item', 'd-flex', 'align-items-center'],
    swal: Ember.inject.service(),
    usersId: Ember.computed.mapBy('usersSelected', 'id'),
    usersSelected: Ember.computed.mapBy('quizAnswers', 'user'),
    isChecked: Ember.computed('usersId', 'user.id', function () {
      return this.usersId.includes(this.user.id);
    }),
    userAvatar: Ember.computed('quizType', function () {
      if (!this.user.active && this.quizType !== 'demissional') {
        return '/images/inactive-user.png';
      }
      if (!this.user.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      return this.user.avatarUrl;
    }),
    remove: (0, _emberConcurrency.task)(function* () {
      let quizAnswer = this.quizAnswers.findBy('user.id', this.user.id);
      try {
        if (quizAnswer.id) {
          if (quizAnswer.finished) {
            let {
              value
            } = yield this.swal.confirm('Confirmar remoção do usuário?', {
              text: 'As respostas deste usuário serão removidas'
            });
            if (!value) {
              return;
            }
          }
          yield quizAnswer.destroyRecord();
        }
        this.quizAnswers.removeObject(quizAnswer);
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      selectUser() {
        if (this.isChecked) {
          this.remove.perform();
        } else {
          this.quizAnswers.addObject({
            user: this.user
          });
        }
      }
    }
  });
  _exports.default = _default;
});