define("pesquisa-talentrh/pods/survey/duplicate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DN1UP3Kt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"survey/form\",[],[[\"@quiz\",\"@quizType\",\"@companyConfiguration\"],[[23,0,[\"model\",\"quiz\"]],[23,0,[\"model\",\"quiz\",\"quizType\"]],[23,0,[\"model\",\"companyConfiguration\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/survey/duplicate/template.hbs"
    }
  });
  _exports.default = _default;
});