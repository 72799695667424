define("pesquisa-talentrh/pods/components/watermark/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qJmnYOBH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",true],[10,\"href\",\"https://voors.com.br/\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n  \"],[7,\"h5\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"img\",true],[10,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors/images/logo-voors-black.svg\"],[10,\"alt\",\"Voors\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/components/watermark/template.hbs"
    }
  });
  _exports.default = _default;
});