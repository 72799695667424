define("pesquisa-talentrh/helpers/sizequiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sizequiz = sizequiz;
  function sizequiz(params /*, hash*/) {
    let size = 0;
    params[0].forEach(item => {
      size += item.questions.length;
    });
    return size;
  }
  var _default = Ember.Helper.helper(sizequiz);
  _exports.default = _default;
});