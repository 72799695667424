define("pesquisa-talentrh/pods/result/general/question/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['col-md-6', 'mb-5'],
    showAnswers: false,
    inputQuestion: Ember.computed('question.type', function () {
      return Ember.get(this, 'question.type') === 'input';
    }),
    endpoint: Ember.computed('quiz', 'section', 'question.index', function () {
      let quiz = Ember.get(this, 'quiz');
      let section = Ember.get(this, 'section');
      let question = Ember.get(this, 'question.index');
      return `/quizAnswerItems/${quiz.id}/chartQuestionData/${section}/${question}`;
    }),
    actions: {
      toggleText() {
        (0, _jquery.default)(event.currentTarget).find('div').toggleClass('text-truncate');
      }
    }
  });
  _exports.default = _default;
});