define("pesquisa-talentrh/pods/model-quiz/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    quizzes: Ember.computed.alias('model.quizzes'),
    user: Ember.computed.alias('model.user'),
    createQuiz: (0, _emberConcurrency.task)(function* (quizModel) {
      try {
        let quiz = this.store.createRecord('quiz', {
          title: quizModel.title,
          color: quizModel.color,
          sections: quizModel.sections,
          user: this.user
        });
        let {
          value
        } = yield this.swal.confirm('Deseja criar um Questionário a partir desse modelo?');
        if (!value) {
          return;
        }
        quiz = yield quiz.save();
        this.transitionToRoute('survey.edit', quiz.id);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});