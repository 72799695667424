define("pesquisa-talentrh/pods/result/answer-multiple/item/component", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    userAvatar: Ember.computed('quizAnswer', function () {
      if (!this.quizAnswer.active) {
        return '/images/inactive-user.png';
      }
      if (!this.quizAnswer.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      return `${_environment.default.bucketUrl}${this.quizAnswer.avatarUrl}`;
    })
  });
  _exports.default = _default;
});