define("pesquisa-talentrh/pods/result/answer-multiple/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    filters: null,
    departmentFilter: null,
    userFilter: null,
    quiz: Ember.computed.alias('model'),
    init() {
      this._super(...arguments);
      this.selectedFilter = {
        label: 'Todas',
        filter: 'selectAll'
      };
    },
    filterUser: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable(),
    getFilters() {
      let filters = {};
      filters.quiz = this.filters.quiz;
      if (this.userFilter) {
        filters.term = this.userFilter;
      }
      if (this.departmentFilter) {
        filters.departments = this.departmentFilter.map(d => d.id);
      }
      Ember.set(this, 'filters', filters);
    },
    actions: {
      clearFilters() {
        Ember.setProperties(this, {
          userFilter: null,
          departmentFilter: null
        });
        this.getFilters();
      },
      filterDepartment(selected) {
        Ember.set(this, 'departmentFilter', selected);
        this.getFilters();
      }
    }
  });
  _exports.default = _default;
});