define("pesquisa-talentrh/pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel() {
      this.session.prohibitAuthentication('home');
    },
    model(params, transition) {
      return transition.to.queryParams.url;
    }
  });
  _exports.default = _default;
});