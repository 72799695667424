define("pesquisa-talentrh/pods/components/percentage-circle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);
      if (!Ember.get(this, 'color')) Ember.set(this, 'color', getComputedStyle(document.body).getPropertyValue('--primary'));
    },
    didRender() {
      this.startAnimation(Ember.get(this, 'percentageNumber'), Ember.get(this, 'color'));
    },
    startAnimation(percentage, color) {
      if (percentage > 100 || percentage < 0) throw new Error('the percentage accepted is between 0 to 100');
      const c = this.$('canvas')[0];
      const ctx = c.getContext('2d');
      const startAngle = 1.5 * Math.PI;
      if (percentage === 0) {
        ctx.beginPath();
        ctx.arc(180, 180, 150, startAngle, 11);
        ctx.strokeStyle = '#ebecf2';
        ctx.lineWidth = 13;
        ctx.stroke();
        return;
      }

      // plus 1 for better array mount
      percentage = Math.floor(percentage) + 1;
      const percentageArr = this.percentageToArrayOfRadians(percentage);
      let count = 0;
      this.drawCircle(ctx, startAngle, startAngle, color);
      const animation = setInterval(() => {
        this.drawCircle(ctx, startAngle, percentageArr[count], color);
        count++;
        if (count >= percentage) clearInterval(animation);
      }, 7);
    },
    degreesToRadians(deg) {
      return deg / 180 * Math.PI;
    },
    percentToRadians(percentage) {
      // convert the percentage into degrees
      const degrees = percentage * 360 / 100;
      // and so that arc begins at top of circle (not 90 degrees) we add 270 degrees
      return this.degreesToRadians(degrees + 270);
    },
    percentageToArrayOfRadians(percentage) {
      return Array.from({
        length: percentage
      }, (v, k) => this.percentToRadians(k));
    },
    drawCircle(ctx, startAngle, endAngle, color) {
      // clear the rect to not bug the stroke line
      ctx.clearRect(0, 0, 360, 360);

      // blue guy
      ctx.beginPath();
      ctx.arc(180, 180, 150, startAngle, endAngle);
      ctx.strokeStyle = color;
      ctx.lineWidth = 13;
      ctx.stroke();
      ctx.save();

      // grey guy
      ctx.beginPath();
      ctx.arc(180, 180, 150, endAngle, startAngle);
      ctx.strokeStyle = '#ebecf2';
      ctx.lineWidth = 13;
      ctx.stroke();
    }
  });
  _exports.default = _default;
});