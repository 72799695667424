define("pesquisa-talentrh/intro/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    steps: [{
      element: '[data-step="home-1"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Olá, bem-vindo ao Voors Pesquisas!</h6>
        <div>Aqui você pode criar, enviar e gerenciar pesquisas com diversas finalidades. Continue o tutorial para conhecer as funcionalidades!</div>
      `
    }, {
      element: '[data-step="home-2"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Questionários recentes</h6>
        <div>Aqui você visualiza os últimos questionários criados e, de forma rápida, pode iniciar a criação do seu questionário.</div>
      `
    }, {
      element: '[data-step="home-3"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Modelos de questionários</h6>
        <div>Para auxiliar no envio de suas pesquisas, disponibilizamos alguns modelos. É só clicar sobre um modelo e criar um questionário a partir dele.</div>
      `
    }, {
      element: '[data-step="home-4"]',
      intro: `
        <div class="font-weight-bold text-primary">Análise dos Questionários</div>
        <div>Aqui são exibidos gráficos, com informações importantes, sobre seus questionários.</div>
      `,
      scrollTo: 'tooltip'
    }, {
      element: '[data-step="home-5"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Acesso ao tutorial</h6>
        <div>Surgiu alguma dúvida? Você pode acessar o tutorial novamente clicando aqui.</div>
      `
    }]
  };
  _exports.default = _default;
});