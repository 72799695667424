define("pesquisa-talentrh/pods/survey/index/change-validity/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    changeValidity: (0, _emberConcurrency.task)(function* () {
      try {
        if ((0, _moment.default)(this.quiz.validity).isBefore(Date.now())) {
          return this.swal.warning('A data não pode ser anterior a hoje');
        }
        if (this.quiz.status === 'F') {
          Ember.set(this, 'quiz.status', 'A');
        }
        yield this.quiz.save();
        Ember.set(this, 'openModal', false);
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      closeModal() {
        this.quiz.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});