define("pesquisa-talentrh/pods/survey/index/send/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(_ref) {
      let {
        id
      } = _ref;
      this.store.query('quiz', {
        sort: 'updatedAt DESC'
      }).then(q => q.toArray()).then(q => q.map(q => q.id)).then(q => {
        if (!q.includes(id)) {
          return this.router.transitionTo('home');
        }
      });
      return this.modelTask.perform(id);
    },
    async deactivate() {
      this.controller.set('activeTab', 0);
      const {
        quizType
      } = this.controllerFor('survey.index').getProperties('quizType');
      /**
       * Garante tempo suficiente para o backend terminar de enviar e-mail
       * e contabilizar novo envio ao relistar os registros
       */
      await (0, _emberConcurrency.timeout)(1000);
      Ember.set(this.controllerFor('survey.index'), 'filters', {
        quizType
      });
    },
    modelTask: (0, _emberConcurrency.task)(function* (id) {
      try {
        let quiz = yield this.store.findRecord('quiz', id);
        let quizAnswers;
        if (quiz.quizType === 'demissional') {
          quizAnswers = [];
        } else {
          quizAnswers = yield this.store.query('quiz-answer', {
            quiz: id,
            noLimit: true
          }).then(o => o.toArray());
        }
        return Ember.RSVP.hash({
          quiz,
          quizAnswers
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});