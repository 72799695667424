define("pesquisa-talentrh/models/quiz-answer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    anonymous: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    answers: (0, _attr.default)('raw', {
      defaultValue() {
        return [{
          questions: [{
            alternatives: [{
              label: null,
              order: 0
            }],
            answer: null,
            order: 0,
            required: false,
            title: null,
            type: 'radio'
          }]
        }];
      }
    }),
    createdAt: (0, _attr.default)('date'),
    finished: (0, _attr.default)('boolean'),
    timeCompletion: (0, _attr.default)('number'),
    //Em segundos
    token: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    deadlineAnswer: (0, _attr.default)('date'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    createdBy: (0, _relationships.belongsTo)('user'),
    quiz: (0, _relationships.belongsTo)('quiz'),
    user: (0, _relationships.belongsTo)('user'),
    // Computed Properties
    timeElapsed: Ember.computed('timeCompletion', function () {
      let str = '';
      let duration = _moment.default.duration(this.timeCompletion, 'seconds');
      if (duration.get('H')) {
        str = `${duration.get('H')}h `;
      }
      if (duration.get('m')) {
        str += `${duration.get('m')}m `;
      }
      return `${str}${duration.get('s')}s`;
    })
  });
  _exports.default = _default;
});