define("pesquisa-talentrh/pods/model-quiz-demissional/question/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['survey-form__question', 'px-3'],
    isCheckbox: Ember.computed('question.type', function () {
      return this.question.type === 'checkbox';
    }),
    isRadio: Ember.computed('question.type', function () {
      return this.question.type === 'radio';
    }),
    isSelect: Ember.computed('question.type', function () {
      return this.question.type === 'select';
    })
  });
  _exports.default = _default;
});