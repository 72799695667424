define("pesquisa-talentrh/pods/components/survey-form/section/question/alternative/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "U7zsigwv",
    "block": "{\"symbols\":[\"@alternative\",\"@question\"],\"statements\":[[4,\"if\",[[24,[\"isRadio\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ui-radio\",[[12,\"class\",\"break-word\"]],[[\"@maxlength\",\"@option\",\"@selected\",\"@onChange\"],[254,[23,1,[]],[23,2,[\"answer\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"answer\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isCheckbox\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ui-checkbox\",[[12,\"class\",\"break-word\"]],[[\"@maxlength\",\"@checked\",\"@value\",\"@onChange\"],[254,[22,\"isChecked\"],[23,1,[]],[28,\"action\",[[23,0,[]],\"checkAnswer\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/components/survey-form/section/question/alternative/template.hbs"
    }
  });
  _exports.default = _default;
});