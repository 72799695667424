define("pesquisa-talentrh/pods/answer/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    menuControl: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel() {
      Ember.set(this, 'menuControl.show', false);
    },
    model(params, transition) {
      return Ember.get(this, 'modelTask').perform(params, transition);
    },
    modelTask: (0, _emberConcurrency.task)(function* (params, transition) {
      let query = '';
      if (transition.to.queryParams && transition.to.queryParams.answer) {
        query = `?answer=${transition.to.queryParams.answer}`;
      }
      try {
        // A associação "quiz" também é colocada no store, pois o usuário pode não estar logado
        let {
          quizModel,
          ...quizAnswer
        } = yield this.ajax.post(`quizAnswers/createAnswer/${params.id}${query}`);
        this.store.pushPayload({
          quizAnswers: [quizAnswer]
        });
        if (this.quizModel) {
          this.store.pushPayload({
            quiz: [quizModel]
          });
        }
        return this.store.peekRecord('quizAnswer', quizAnswer.id);
      } catch (e) {
        if (e.status === 401) {
          this.replaceWith('login', {
            queryParams: {
              url: params.id
            }
          });
        } else {
          this.replaceWith('answer-forbidden', {
            queryParams: {
              title: 'Ops!',
              subtitle: e.payload.msg
            }
          });
        }
      }
    }),
    actions: {
      willTransition() {
        this._super();
        Ember.set(this, 'menuControl.show', true);
      }
    }
  });
  _exports.default = _default;
});