define("pesquisa-talentrh/models/company-configuration", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    generalOrSpecific: _emberData.default.attr('number'),
    permitChat: _emberData.default.attr('boolean'),
    permitAgenda: _emberData.default.attr('boolean'),
    permitNews: _emberData.default.attr('boolean'),
    permitChart: _emberData.default.attr('boolean'),
    permitSurvey: _emberData.default.attr('boolean'),
    permitMural: _emberData.default.attr('boolean'),
    permitScale: _emberData.default.attr('boolean'),
    permitAd: _emberData.default.attr('boolean'),
    permitNotify: _emberData.default.attr('boolean'),
    permitSuggestionBox: _emberData.default.attr('boolean'),
    permitCompanyPhotos: _emberData.default.attr('boolean'),
    permitUserPhotos: _emberData.default.attr('boolean'),
    permitBiblioteca: _emberData.default.attr('boolean'),
    permitReceipt: _emberData.default.attr('boolean'),
    permitRend: _emberData.default.attr('boolean'),
    permitCartaoPonto: _emberData.default.attr('boolean'),
    permitMessageRh: _emberData.default.attr('boolean'),
    permitDocument: _emberData.default.attr('boolean'),
    permitCarona: _emberData.default.attr('boolean'),
    permitContactList: _emberData.default.attr('boolean'),
    permitEvent: _emberData.default.attr('boolean'),
    permitTraining: _emberData.default.attr('boolean'),
    permitAnnouncement: _emberData.default.attr('boolean'),
    permitProjectsAndTasks: _emberData.default.attr('boolean'),
    permitPolicy: _emberData.default.attr('boolean'),
    permitBenefit: _emberData.default.attr('boolean'),
    folhaLocalURL: _emberData.default.attr('string'),
    userApproval: _emberData.default.attr('boolean'),
    permitMeetingMinute: _emberData.default.attr('boolean'),
    picturesDashboard: _emberData.default.attr('boolean'),
    permitComercialPolitics: _emberData.default.attr('boolean'),
    subscription: _emberData.default.attr('raw'),
    permitDecisionMaking: _emberData.default.attr('boolean'),
    permitForum: _emberData.default.attr('boolean'),
    communicationApi: _emberData.default.attr('boolean'),
    thumbLogo: _emberData.default.attr('string'),
    featureFlags: _emberData.default.attr('raw'),
    // aliases
    company: _emberData.default.belongsTo('company'),
    showPortalColab: Ember.computed('permitReceipt', 'permitMessageRh', 'jobContract', function () {
      return this.get('permitReceipt') | this.get('permitMessageRh') && this.get('hasPortalColabModule');
    }),
    hasProjectManagerModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('PROJECT-MANAGER');
    }),
    hasTEDModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('TED');
    }),
    hasTrainingModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('TRAINING');
    }),
    hasPortalColabModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('PORTAL_COLAB');
    }),
    hasPortalGestorModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('PORTAL_GESTOR');
    }),
    hasAVDModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('AVD');
    }),
    hasPesquisaModule: Ember.computed('subscription', function () {
      return this.get('subscription.availableModules').includes('PESQUISA');
    }),
    accountIsActive: Ember.computed('subscription', function () {
      return this.get('subscription.active') || false;
    })
  });
  _exports.default = _default;
});