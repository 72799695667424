define("pesquisa-talentrh/components/ui-form-renderer", ["exports", "ui-nela/components/ui-form-renderer"], function (_exports, _uiFormRenderer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiFormRenderer.default;
    }
  });
});