define("pesquisa-talentrh/pods/model-quiz/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return Ember.RSVP.hash({
        quizzes: this.store.query('quiz-model', {
          quizType: 'search'
        }),
        user: this.permission.user
      });
    },
    afterModel() {
      const accessProfile = this.permission.accessProfile;
      if (!accessProfile.allowManageSearches && !accessProfile.admin && !accessProfile.allowCreateQuestionnaires) {
        this.swal.warning('Você não possui permissão para gerenciar pesquisas. Entre em contato com o Administrador do sistema.');
        this.transition('home');
      }
    }
  });
  _exports.default = _default;
});