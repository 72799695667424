define("pesquisa-talentrh/services/menu-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    show: true
  });
  _exports.default = _default;
});