define("pesquisa-talentrh/nela-datepicker/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/nela-datepicker.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-datepicker.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-datetimepicker.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-datetimepicker.hbs should pass TemplateLint.\n\n');
  });
});