define("pesquisa-talentrh/pods/survey/index/send/groups/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['tab-pane', 'fade', 'survey-send-groups'],
    ajax: Ember.inject.service(),
    contactService: Ember.inject.service(),
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    filterGroups: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(250);
      return this.contactService.filterGroups(term);
    }).on('didInsertElement').restartable(),
    loadList: (0, _emberConcurrency.task)(function* () {
      yield this.contactService.loadList();
    }).on('didInsertElement').restartable(),
    send: (0, _emberConcurrency.task)(function* (group) {
      try {
        let {
          value
        } = yield this.swal.confirm(`Enviar para o grupo ${group.name}`, {
          text: 'Todos os participantes serão notificados!'
        });
        if (!value) {
          return;
        }
        let id = Ember.get(this, 'quiz.id');
        yield this.ajax.post('quizzes/send', {
          data: {
            id,
            group: group.id
          }
        });
        this.swal.success('Questionário enviado com sucesso!');
        this.router.transitionTo('survey');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});