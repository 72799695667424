define("pesquisa-talentrh/pods/components/percentage-circle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TIIZK/Nj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"circle-progress circle-pie-chart\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"circle-canvas\"],[8],[0,\"\\n    \"],[7,\"canvas\",true],[10,\"width\",\"360\"],[10,\"height\",\"360\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[1,[22,\"percentageNumber\"],false],[0,\"%\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/components/percentage-circle/template.hbs"
    }
  });
  _exports.default = _default;
});