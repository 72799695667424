define("pesquisa-talentrh/utils/answer", ["exports", "sweet-alert2"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = answer;
  function answer(model) {
    let isComplete = true;
    model.forEach(pages => {
      pages.questions.forEach(question => {
        if (question.required && !question.answer) {
          _sweetAlert.default.fire('Ops!', 'Há questões obrigatórias não respondidas.', 'warning');
          isComplete = false;
        }
        if (question.answer && question.type !== 'input') {
          if (Array.isArray(question.answer)) {
            question.answer.forEach(answer => {
              question.alternatives.forEach(item => {
                if (answer === item.label && item.reason && !question.reason) {
                  _sweetAlert.default.fire('Ops!', 'Há questões a serem justificadas.', 'warning');
                  isComplete = false;
                }
              });
            });
          } else {
            question.alternatives.forEach(item => {
              if (question.answer === item.label && item.reason && !question.reason) {
                _sweetAlert.default.fire('Ops!', 'Há questões a serem justificadas.', 'warning');
                isComplete = false;
              }
            });
          }
        }
      });
    });
    return isComplete;
  }
});