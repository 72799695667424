define("pesquisa-talentrh/pods/components/watermark/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['survey-watermark', 'position-relative', 'text-center', 'w-100', 'mt-4', 'mb-3']
  });
  _exports.default = _default;
});