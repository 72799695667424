define("pesquisa-talentrh/pods/survey/index/send/users/selected/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-selected'],
    swal: Ember.inject.service(),
    removeUser: (0, _emberConcurrency.task)(function* () {
      let quizAnswer = this.quizAnswers.findBy('user.id', Ember.get(this, 'user.id'));
      try {
        if (quizAnswer.id) {
          if (quizAnswer.finished || quizAnswer.anonymous) {
            let {
              value
            } = yield this.swal.confirm('Deseja mesmo remover?', {
              text: 'As respostas deste usuário serão removidas'
            });
            if (!value) {
              return;
            }
          }
          yield quizAnswer.destroyRecord();
        }
        this.quizAnswers.removeObject(quizAnswer);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});