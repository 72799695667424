define("pesquisa-talentrh/pods/survey/index/send/users/selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4Ka4X8QV",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex align-items-center\"],[8],[0,\"\\n  \"],[5,\"ui-avatar\",[[12,\"class\",\"avatar-3 mr-1\"],[12,\"alt\",[23,1,[\"fullName\"]]]],[[\"@src\"],[[23,1,[\"avatar\"]]]]],[0,\"\\n  \"],[1,[28,\"or\",[[23,1,[\"fullName\"]],\"Anônimo\"],null],false],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn p-0 ml-2\"],[11,\"onclick\",[28,\"perform\",[[24,[\"removeUser\"]]],null]],[8],[0,\"\\n    | \"],[7,\"i\",true],[10,\"class\",\"fa fa-times align-middle\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/survey/index/send/users/selected/template.hbs"
    }
  });
  _exports.default = _default;
});