define("pesquisa-talentrh/pods/components/survey-form/component", ["exports", "ember-concurrency", "pesquisa-talentrh/utils/answer", "moment"], function (_exports, _emberConcurrency, _answer, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['survey-answer'],
    router: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    activeSection: 'navSection0',
    timer: (0, _moment.default)(),
    answers: Ember.computed.alias('quizAnswer.answers'),
    quiz: Ember.computed.alias('quizAnswer.quiz'),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    isFirstSection: Ember.computed('activeSection', function () {
      let [index] = this.activeSection.match(/\d+$/g);
      return !parseInt(index);
    }),
    isLastSection: Ember.computed('activeSection', 'quiz.sections', function () {
      let [index] = this.activeSection.match(/\d+$/g);
      return Ember.get(this, 'quiz.sections.length') - 1 === parseInt(index);
    }),
    sendAnswer: (0, _emberConcurrency.task)(function* () {
      if ((0, _answer.default)(this.answers)) {
        try {
          let {
            value
          } = yield this.swal.confirm('Confirmar envio das respostas?');
          if (!value) {
            return;
          }
          Ember.setProperties(this.quizAnswer, {
            finished: true,
            timeCompletion: (0, _moment.default)().diff(this.timer, 'seconds')
          });
          let quizAnswer = yield this.quizAnswer.save();
          this.router.replaceWith('answer-completed', quizAnswer.id, {
            queryParams: {
              title: 'Obrigado pela sua resposta'
            }
          });
        } catch (e) {
          this.swal.catch(e);
        }
      }
    }).drop(),
    scrollSectionIntoView() {
      let nav = document.getElementById('survey-navigation');
      let active = document.getElementById(this.activeSection);
      let offsetRight = active.offsetLeft + active.offsetWidth;
      if (active.offsetLeft < nav.scrollLeft) {
        active.scrollIntoView({
          block: 'nearest',
          inline: 'start'
        });
        nav.scrollBy(-32, 0);
      } else if (offsetRight - nav.scrollLeft > nav.offsetWidth) {
        active.scrollIntoView({
          block: 'nearest',
          inline: 'end'
        });
        nav.scrollBy(32, 0);
      }
    },
    actions: {
      nextSection() {
        let [nextSection] = this.activeSection.match(/\d+$/g);
        nextSection = parseInt(nextSection) + 1;
        if (nextSection < Ember.get(this, 'quiz.sections.length')) {
          document.getElementById(this.activeSection).classList.remove('active');
          Ember.set(this, 'activeSection', `navSection${nextSection}`);
          this.scrollSectionIntoView();
        }
      },
      previousSection() {
        let [previousSection] = this.activeSection.match(/\d+$/g);
        previousSection = parseInt(previousSection) - 1;
        if (previousSection >= 0) {
          document.getElementById(this.activeSection).classList.remove('active');
          Ember.set(this, 'activeSection', `navSection${previousSection}`);
          this.scrollSectionIntoView();
        }
      }
    }
  });
  _exports.default = _default;
});