define("pesquisa-talentrh/pods/answer-forbidden/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    menuControl: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      Ember.set(this, 'menuControl.show', false);
    },
    model(params, transition) {
      return Ember.RSVP.hash({
        title: transition.to.queryParams.title || 'Ops!',
        subtitle: transition.to.queryParams.subtitle || 'Você não pode responder este questionário!'
      });
    },
    actions: {
      willTransition() {
        this._super();
        Ember.set(this, 'menuControl.show', true);
      }
    }
  });
  _exports.default = _default;
});