define("pesquisa-talentrh/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EgnnaSFk",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui-login\",[],[[\"@icon\",\"@appName\",\"@onAuthenticate\"],[\"pesquisa\",\"Pesquisa\",[28,\"action\",[[23,0,[]],\"authenticate\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/login/template.hbs"
    }
  });
  _exports.default = _default;
});