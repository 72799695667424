define("pesquisa-talentrh/pods/survey-demissional/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    redirect: function () {
      this.router.transitionTo('survey', {
        queryParams: {
          quizType: 'demissional'
        }
      });
    }
  });
  _exports.default = _default;
});