define("pesquisa-talentrh/models/user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "pesquisa-talentrh/config/environment"], function (_exports, _model, _attr, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    active: (0, _attr.default)('boolean'),
    admin: (0, _attr.default)('boolean'),
    address: (0, _attr.default)('string'),
    avatarUrl: (0, _attr.default)('string'),
    birthDate: (0, _attr.default)('date'),
    cellphone: (0, _attr.default)('string'),
    complement: (0, _attr.default)('string'),
    confirmPassword: (0, _attr.default)('string'),
    cpf: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    gender: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    neighborhood: (0, _attr.default)('string'),
    newPassword: (0, _attr.default)('string'),
    number: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    personalEmail: (0, _attr.default)('string'),
    preferences: (0, _attr.default)('raw'),
    superUser: (0, _attr.default)('boolean'),
    username: (0, _attr.default)('string'),
    zipcode: (0, _attr.default)('string'),
    // Associations
    accessProfile: (0, _relationships.belongsTo)('access-profile'),
    birthCity: (0, _relationships.belongsTo)('city'),
    city: (0, _relationships.belongsTo)('city'),
    company: (0, _relationships.belongsTo)('company'),
    department: (0, _relationships.belongsTo)('department'),
    jobFunction: (0, _relationships.belongsTo)('job-function'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (!this.active) {
        return `(INATIVO) ${this.firstName} ${this.lastName}`;
      }
      return `${this.firstName} ${this.lastName}`;
    }),
    avatar: Ember.computed('avatarUrl', function () {
      if (!this.active) {
        return '/images/inactive-user.png';
      }
      if (!this.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      return this.avatarUrl;
    })
  });
  _exports.default = _default;
});