define("pesquisa-talentrh/models/access-profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    admin: _emberData.default.attr('boolean'),
    allowCreateGoals: _emberData.default.attr('boolean'),
    allowManageGoals: _emberData.default.attr('boolean'),
    allowManageSearches: _emberData.default.attr('boolean'),
    allowAccessSearches: _emberData.default.attr('boolean'),
    allowCreateQuestionnaires: _emberData.default.attr('boolean'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company'),
    accessRules: _emberData.default.hasMany('access-rule'),
    users: _emberData.default.hasMany('user'),
    defaultProfile: _emberData.default.attr('boolean'),
    postAnnouncement: _emberData.default.attr('boolean'),
    postEvent: _emberData.default.attr('boolean'),
    publishAlbum: _emberData.default.attr('boolean'),
    manager: _emberData.default.attr('boolean'),
    closedOnMonday: _emberData.default.attr('boolean'),
    closedOnTuesday: _emberData.default.attr('boolean'),
    closedOnWednesday: _emberData.default.attr('boolean'),
    closedOnThursday: _emberData.default.attr('boolean'),
    closedOnFriday: _emberData.default.attr('boolean'),
    closedOnSaturday: _emberData.default.attr('boolean'),
    closedOnSunday: _emberData.default.attr('boolean'),
    mondayOpenTime: _emberData.default.attr('string'),
    tuesdayOpenTime: _emberData.default.attr('string'),
    wednesdayOpenTime: _emberData.default.attr('string'),
    thursdayOpenTime: _emberData.default.attr('string'),
    fridayOpenTime: _emberData.default.attr('string'),
    saturdayOpenTime: _emberData.default.attr('string'),
    sundayOpenTime: _emberData.default.attr('string'),
    mondayCloseTime: _emberData.default.attr('string'),
    tuesdayCloseTime: _emberData.default.attr('string'),
    wednesdayCloseTime: _emberData.default.attr('string'),
    thursdayCloseTime: _emberData.default.attr('string'),
    fridayCloseTime: _emberData.default.attr('string'),
    saturdayCloseTime: _emberData.default.attr('string'),
    sundayCloseTime: _emberData.default.attr('string'),
    //treinamento
    instructorPermissions: _emberData.default.attr('boolean'),
    learnerPermissions: _emberData.default.attr('boolean'),
    //avd
    emitRestrictReport: _emberData.default.attr('boolean')
  });
  _exports.default = _default;
});