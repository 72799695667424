define("pesquisa-talentrh/pods/errors/inactive-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sYvCQvEy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"section-body contain-lg\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"text-black-50\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fa fa-meh-o\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"text-black-50\"],[8],[0,\"Opa! Parece que a conta desta empresa está inativa.\"],[9],[0,\"\\n        \"],[7,\"h3\",true],[10,\"class\",\"text-black-50\"],[8],[0,\"Entre em contato com o RH da sua empresa para maiores detalhes.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/errors/inactive-account/template.hbs"
    }
  });
  _exports.default = _default;
});