define("pesquisa-talentrh/pods/add-answer/controller", ["exports", "ember-concurrency", "pesquisa-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    openModal: true,
    userSelected: null,
    anonymousQuiz: Ember.computed.alias('quizAnswer.anonymous'),
    quizAnswer: Ember.computed.alias('model.quizAnswer'),
    firedUsers: Ember.computed.alias('model.firedUsers'),
    showUser: Ember.computed('quizAnswer.anonymous', 'userSelected', function () {
      return !this.anonymousQuiz && this.userSelected;
    }),
    userAvatar: Ember.computed('userSelected', function () {
      if (!this.userSelected.active && this.quiz && this.quiz.quizType !== 'demissional') {
        return '/images/inactive-user.png';
      }
      if (!this.userSelected.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      return `${_environment.default.bucketUrl}${this.userSelected.avatarUrl}`;
    }),
    userSelectedFullName: Ember.computed('userSelected', function () {
      const fullname = this.userSelected.fullName.replace('(INATIVO) ', '');
      return fullname;
    }),
    selectUser: (0, _emberConcurrency.task)(function* () {
      if (!this.userSelected) {
        return this.swal.warning('É necessário selecionar um colaborador');
      }
      try {
        const quiz = yield Ember.get(this, 'quizAnswer.quiz');
        if (quiz.allowMultipleAnswers && !quiz.anonymous) {
          Ember.set(this, 'quizAnswer.user', this.userSelected);
        } else {
          let quizAnswer = yield this.store.queryRecord('quiz-answer', {
            company: this.session.company.id,
            quiz: quiz.id,
            user: this.userSelected.id
          });
          if (quizAnswer) {
            if (quizAnswer.finished) {
              return this.swal.warning('O colaborador selecionado já respondeu o questionário');
            }
            this.model.quizAnswer.rollbackAttributes();
            Ember.set(quizAnswer, 'createdBy', this.store.peekRecord('user', this.session.user.id));
            this.toggleProperty('openModal');
            return Ember.set(this, 'model.quizAnswer', quizAnswer);
          }
          Ember.set(this, 'quizAnswer.user', this.userSelected);
        }
        if (this.quizAnswer.isNew) {
          yield this.quizAnswer.save();
        }
        this.toggleProperty('openModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      onChangeUser(user) {
        Ember.set(this, "userSelected", user);
      }
    }
  });
  _exports.default = _default;
});