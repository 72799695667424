define("pesquisa-talentrh/pods/components/data-loader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    ajax: Ember.inject.service(),
    loadData: (0, _emberConcurrency.task)(function* () {
      try {
        let endpoint = Ember.get(this, 'endpoint');
        return yield Ember.get(this, 'ajax').request(endpoint);
      } catch (e) {
        Ember.debug(e);
      }
    }).on('didReceiveAttrs').drop()
  });
  _exports.default = _default;
});