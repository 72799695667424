define("pesquisa-talentrh/pods/result/final/filters/component", ["exports", "ember-concurrency", "jquery"], function (_exports, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // classNames: ['row'],
    tagName: '',
    ajax: Ember.inject.service(),
    departmentsSelected: null,
    usersSelected: null,
    clearFilter: Ember.computed('departmentsSelected.length', 'usersSelected.length', function () {
      return Ember.get(this, 'departmentsSelected.length') || Ember.get(this, 'usersSelected.length');
    }),
    departments: (0, _emberConcurrency.task)(function* () {
      let quiz = Ember.get(this, 'quiz.id');
      try {
        let {
          departments
        } = yield Ember.get(this, 'ajax').request(`quizzes/${quiz}/departmentsFilterAjax`);
        return departments;
      } catch (e) {
        Ember.debug(e);
      }
    }).on('didInsertElement').drop(),
    users: (0, _emberConcurrency.task)(function* () {
      let quiz = Ember.get(this, 'quiz.id');
      let departments = Ember.get(this, 'departmentsSelected');
      let query = '';
      if (departments && departments.length) {
        departments = departments.map(department => {
          return department.id;
        });
        departments = _jquery.default.param({
          departments
        });
        query = `?${departments}`;
      }
      try {
        let {
          users
        } = yield Ember.get(this, 'ajax').request(`quizzes/${quiz}/usersFilterAjax${query}`);
        return users;
      } catch (e) {
        Ember.debug(e);
      }
    }).on('didInsertElement').drop(),
    actions: {
      clearFilters() {
        Ember.set(this, 'departmentsSelected', null);
        Ember.set(this, 'usersSelected', null);
        Ember.get(this, 'users').perform();
      },
      removeDepartmentFilter(department) {
        let users = Ember.get(this, 'usersSelected');
        if (users) {
          users = users.map(user => {
            if (user.department === department.id) {
              return user;
            }
          });
          Ember.get(this, 'usersSelected').removeObjects(users);
        }
        Ember.get(this, 'departmentsSelected').removeObject(department);
        Ember.get(this, 'users').perform();
      },
      removeUserFilter(user) {
        Ember.get(this, 'usersSelected').removeObject(user);
      },
      selectDepartment(selected) {
        Ember.set(this, 'departmentsSelected', selected);
        Ember.set(this, 'usersSelected', null);
        Ember.get(this, 'users').perform();
      }
    }
  });
  _exports.default = _default;
});