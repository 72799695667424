define("pesquisa-talentrh/pods/survey/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    intro: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    quizType: 'search',
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(params) {
      let {
        quizType
      } = params || 'search';
      if (!quizType) {
        quizType = 'search';
      }
      const session = Ember.get(this, 'session');
      const company = session.get('user.company');
      return Ember.RSVP.hash({
        quizType,
        companyConfiguration: this.store.findRecord('companyConfiguration', company)
      });
    },
    afterModel(model) {
      if (!model.quizType) {
        model.quizType = 'search';
      }
      if (model.quizType !== 'demissional') {
        this.intro.start();
      }
    },
    setupController(controller, model) {
      controller.set('quizType', model.quizType);
      controller.set('companyConfiguration', model.companyConfiguration);
      controller.set('filters', {
        quizType: model.quizType
      });
    }
  });
  _exports.default = _default;
});