define("pesquisa-talentrh/pods/model-quiz-demissional/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    admin: Ember.computed.alias('permission.accessProfile.admin'),
    allowManageSearches: Ember.computed.alias('permission.accessProfile.allowManageSearches'),
    model() {
      const allowed = this.allowManageSearches || this.admin;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
      return this.store.queryRecord('quiz-model', {
        quizType: 'demissional'
      });
    }
  });
  _exports.default = _default;
});