define("pesquisa-talentrh/pods/survey/form/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['container', 'survey-form'],
    swal: Ember.inject.service(),
    saveModal: false,
    didInsertElement() {
      this._super(...arguments);
      (0, _jquery.default)(window).scroll(() => {
        let $scrollTop = (0, _jquery.default)(window).scrollTop();

        // eslint-disable-next-line ember/jquery-ember-run
        (0, _jquery.default)('.section-header').each((index, section) => {
          let $offsetTop = (0, _jquery.default)(section).offset().top - 65;
          if ($scrollTop > $offsetTop) {
            (0, _jquery.default)(section).find(`#sectionHeader${index}`).addClass('section-header--fixed');
            (0, _jquery.default)(section).addClass('pt-5');
          } else {
            (0, _jquery.default)(section).find(`#sectionHeader${index}`).removeClass('section-header--fixed');
            (0, _jquery.default)(section).removeClass('pt-5');
          }
        });
      });
      (0, _jquery.default)(document).ready(function () {
        setTimeout(() => {
          let h = document.querySelector('.quiz-header').offsetHeight;
          h += 75;
          document.querySelector('.color-block').style.height = h + 'px';
        }, 10);
      });
    },
    actions: {
      save() {
        let quiz = this.quiz;
        let canProceed = true;
        let sections = quiz.sections;
        sections.forEach(section => {
          let questions = section.questions;
          questions.forEach(question => {
            let alternatives = question.alternatives;
            let equals = 0;
            let likertColumns = question.likertColumns;
            let likertRows = question.likertRows;
            alternatives.forEach(alternative1 => {
              alternatives.forEach(alternative2 => {
                if (alternative2.label == alternative1.label) {
                  equals++;
                }
              });
            });
            if (equals > alternatives.length) {
              canProceed = false;
              this.swal.warning(`Há duas ou mais alternativas repetidas na questão "${question.title || "sem nome"}" da seção "${section.title || "sem nome"}"!`);
              return;
            }
            ;
            if (question.type === 'likert') {
              likertColumns.forEach(column => {
                if (!column.label) {
                  canProceed = false;
                  return this.swal.warning(`Informe uma descrição para todas as colunas e linhas da pergunta ${question.title || "sem nome"}`);
                }
              });
              likertRows.forEach(row => {
                if (!row.label) {
                  canProceed = false;
                  return this.swal.warning(`Informe uma descrição para todas as colunas e linhas da pergunta ${question.title || "sem nome"}`);
                }
              });
            }
            ;
          });
        });
        if (quiz.title.length > 254) {
          this.swal.warning('Altere a descrição do título, limite permitido 240 caracteres. ');
        }
        if (canProceed) {
          Ember.set(this, 'saveModal', true);
        }
      },
      resizeBlueArea() {
        setTimeout(function () {
          let h = document.querySelector('.quiz-header').offsetHeight;
          h += 75;
          document.querySelector('.color-block').style.height = h + 'px';
        }, 10);
      }
    }
  });
  _exports.default = _default;
});