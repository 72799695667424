define("pesquisa-talentrh/services/nela", ["exports", "pesquisa-talentrh/services/ajax", "pesquisa-talentrh/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    host: _environment.default.apiUrl.social
  });
  _exports.default = _default;
});