define("pesquisa-talentrh/pods/survey/edit/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(_ref) {
      let {
        id
      } = _ref;
      const session = Ember.get(this, 'session');
      const company = session.get('user.company');
      this.store.query('quiz', {
        sort: 'updatedAt DESC'
      }).then(q => q.toArray()).then(q => q.map(q => q.id)).then(q => {
        if (!q.includes(id)) {
          return this.router.transitionTo('home');
        }
      });
      return Ember.RSVP.hash({
        quiz: this.store.findRecord('quiz', id),
        companyConfiguration: this.store.findRecord('companyConfiguration', company)
      });
    },
    afterModel(model, transition) {
      return Ember.get(this, 'afterModelTask').perform(model.quiz, transition);
    },
    deactivate() {
      this.controller.model.quiz.rollbackAttributes();
    },
    afterModelTask: (0, _emberConcurrency.task)(function* (quiz, transition) {
      const swal = Ember.get(this, 'swal');
      try {
        if (quiz.answers || quiz.sentQuiz) {
          transition.abort();
          let {
            value
          } = yield swal.confirm('Você não pode editar um questionário que já foi enviado ou respondido', {
            text: 'Deseja fechar o questionário atual e criar uma cópia?',
            confirmButtonText: 'Copiar',
            confirmButtonColor: '#6475FD'
          });
          if (!value) {
            return;
          }
          Ember.set(quiz, 'status', 'F');
          yield quiz.save();
          this.transitionTo('survey.duplicate', quiz.id);
        }
      } catch (e) {
        swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});