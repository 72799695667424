define("pesquisa-talentrh/pods/survey/index/send/sms/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G2g6aYgz",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[5,\"ui-checkbox\",[],[[\"@checked\",\"@onChange\"],[[23,0,[\"isChecked\"]],[28,\"action\",[[23,0,[]],\"selectUser\"],null]]],{\"statements\":[[0,\" \"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"ui-avatar\",[[12,\"class\",\"avatar-4 mr-2\"],[12,\"alt\",[23,1,[\"fullName\"]]]],[[\"@src\"],[[23,0,[\"userAvatar\"]]]]],[0,\"\\n\"],[7,\"strong\",true],[8],[1,[23,1,[\"fullName\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/survey/index/send/sms/item/template.hbs"
    }
  });
  _exports.default = _default;
});