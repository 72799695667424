define("pesquisa-talentrh/pods/survey/view/question/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    alternatives: Ember.computed.mapBy('question.alternatives', 'label'),
    isInput: Ember.computed.equal('question.type', 'input'),
    isSelect: Ember.computed.equal('question.type', 'select'),
    isClassification: Ember.computed.equal('question.type', 'classification'),
    isLinearGrid: Ember.computed.equal('question.type', 'linearGrid'),
    isLikert: Ember.computed.equal('question.type', 'likert'),
    isRadio: Ember.computed.equal('question.type', 'radio'),
    isCheckbox: Ember.computed.equal('question.type', 'checkbox')
  });
  _exports.default = _default;
});