define("pesquisa-talentrh/pods/survey/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(params, transition) {
      let {
        quizType
      } = transition.to.queryParams || 'search';
      if (!quizType) {
        quizType = 'search';
      }
      const session = Ember.get(this, 'session');
      const company = session.get('user.company');
      return Ember.RSVP.hash({
        quiz: this.store.createRecord('quiz', {
          quizType
        }),
        user: this.permission.user,
        quizType,
        companyConfiguration: this.store.findRecord('companyConfiguration', company)
      });
    },
    afterModel(model) {
      const {
        quizType
      } = model;
      const accessProfile = this.permission.accessProfile;
      if (!accessProfile.allowManageSearches && !accessProfile.admin && !accessProfile.allowCreateQuestionnaires && quizType === 'demissional') {
        this.swal.warning('Você não possui permissão para gerenciar pesquisas. Entre em contato com o Administrador do sistema.');
        this.transition('home');
      }
    },
    deactivate() {
      this.controller.model.quiz.rollbackAttributes();
    }
  });
  _exports.default = _default;
});