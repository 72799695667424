define("pesquisa-talentrh/pods/survey/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: Ember.A(['quizType']),
    filters: null,
    openFilterModal: false,
    quizType: null,
    companyConfiguration: null,
    statusOptions: Ember.A([{
      label: 'Rascunho',
      value: 'R'
    }, {
      label: 'Aberto',
      value: 'A'
    }, {
      label: 'Fechado',
      value: 'F'
    }]),
    reload: Ember.computed('quizType', function () {
      this.getFilters();
      return true;
    }),
    filterTitle: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable(),
    getFilters() {
      let filters = {};
      if (this.statusFilter) {
        filters.status = this.statusFilter.value;
      }
      if (this.titleFilter) {
        filters.title = {
          contains: this.titleFilter
        };
      }
      filters.quizType = this.quizType;
      Ember.set(this, 'filters', filters);
    },
    actions: {
      clearFilters() {
        Ember.setProperties(this, {
          filters: {
            quizType: this.quizType
          },
          statusFilter: null,
          titleFilter: null
        });
      },
      filterStatus(selected) {
        Ember.set(this, 'statusFilter', selected);
        this.getFilters();
      }
    }
  });
  _exports.default = _default;
});