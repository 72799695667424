define("pesquisa-talentrh/router", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('add-answer', {
      path: 'add-answer/:id'
    });
    this.route('answer', {
      path: 'responder/:id'
    });
    this.route('answer-completed', {
      path: 'answer-completed/:id'
    });
    this.route('answer-forbidden');
    this.route('answer-view', {
      path: 'answer-view/:quiz_answer_id'
    });
    this.route('answer-view-user', {
      path: 'answer-view-user/:quiz_id/:user_id'
    });
    this.route('badurl', {
      path: '/*badurl'
    });
    this.route('errors', function () {
      this.route('forbidden');
      this.route('inactive-account');
      this.route('not-found');
    });
    this.route('home', {
      path: '/'
    });
    this.route('login');
    this.route('model-quiz');
    this.route('result', {
      path: 'result/:quiz_id'
    }, function () {
      this.route('answer');
      this.route('final');
      this.route('general');
      this.route('answer-multiple');
    });
    this.route('survey', function () {
      this.route('duplicate', {
        path: ':id/duplicate'
      });
      this.route('edit', {
        path: ':id/edit'
      });
      this.route('view', {
        path: ':id/view'
      });
      this.route('index', {
        path: ''
      }, function () {
        this.route('send', {
          path: ':id/send'
        });
      });
      this.route('new');
      this.route('view', {
        path: ':id/view'
      });
      this.route('demissional');
    });
    this.route('survey-demissional');
    this.route('survey-search');
    this.route('model-quiz-demissional');
    this.route('privacy-policy');
  });
  var _default = Router;
  _exports.default = _default;
});