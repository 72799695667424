define("pesquisa-talentrh/components/ui-card/header/btn-group", ["exports", "ui-nela/components/ui-card/header/btn-group"], function (_exports, _btnGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _btnGroup.default;
    }
  });
});