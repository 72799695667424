define("pesquisa-talentrh/pods/survey/form/section-options/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['d-flex'],
    swal: Ember.inject.service(),
    deleteSection: (0, _emberConcurrency.task)(function* () {
      let sections = Ember.get(this, 'sections');
      let section = Ember.get(this, 'section');
      let {
        value
      } = yield Ember.get(this, 'swal').confirm('Excluir seção?', {
        text: 'Todas as questões serão excluídas'
      });
      if (!value) {
        return;
      }
      sections.removeObject(section);
    }),
    actions: {
      cloneSection() {
        let questions = this.section.questions.map(question => {
          let alternatives = question.alternatives.map(alt => {
            return {
              label: alt.label,
              order: alt.order,
              reason: alt.reason
            };
          });
          let likertRows = [];
          let likertColumns = [];
          if (question.type === 'likert') {
            question.likertRows.forEach(row => {
              let rowAux = {
                label: row.label,
                order: Math.random()
              };
              likertRows.push(rowAux);
            });
            question.likertColumns.forEach(colum => {
              let columAux = {
                label: colum.label,
                order: Math.random()
              };
              likertColumns.push(columAux);
            });
          }
          return {
            alternatives,
            description: question.description,
            order: question.order,
            required: question.required,
            title: question.title,
            type: question.type,
            level: question.level,
            label1: question.label1,
            label2: question.label2,
            simbol: question.simbol,
            likertRows: likertRows,
            likertColumns: likertColumns,
            alternativesAux: question.alternativesAux
          };
        });
        let section = {
          description: this.section.description,
          order: this.sections.length,
          questions,
          title: this.section.title
        };
        this.sections.pushObject(section);
      },
      newQuestion() {
        let questions = Ember.get(this, 'section.questions');
        questions.pushObject({
          title: null,
          type: 'radio',
          order: questions.length,
          alternatives: [{
            label: null,
            order: 0
          }]
        });
      },
      newSection() {
        let sections = Ember.get(this, 'sections');
        sections.pushObject({
          questions: [{
            title: null,
            type: 'radio',
            order: 0,
            alternatives: [{
              label: null,
              order: 0
            }]
          }]
        });
      }
    }
  });
  _exports.default = _default;
});