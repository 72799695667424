define("pesquisa-talentrh/pods/survey/index/demissional-quiz/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    createQuizByModel: (0, _emberConcurrency.task)(function* () {
      try {
        const demissionalQuizModel = yield this.store.queryRecord('quiz-model', {
          quizType: 'demissional'
        });
        let quiz = this.store.createRecord('quiz', {
          title: demissionalQuizModel.title,
          color: demissionalQuizModel.color,
          sections: demissionalQuizModel.sections,
          user: this.session.user,
          public: true,
          anonymous: false,
          quizType: 'demissional'
        });
        let {
          value
        } = yield this.swal.confirm('Deseja criar um Questionário a partir desse modelo?');
        if (!value) {
          return;
        }
        quiz = yield quiz.save();
        Ember.get(this, 'routing').transitionTo('survey.edit', [quiz.id]);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});