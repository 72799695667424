define("pesquisa-talentrh/pods/components/user-attribute/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML atrributes
    tagName: '',
    // Services
    store: Ember.inject.service(),
    // Default values
    hasError: false,
    isLoaded: false,
    user: null,
    // Lifecycle hooks
    didReceiveAttrs() {
      this._super(...arguments);
      const userId = parseInt(this.userId);
      const currentId = parseInt(Ember.get(this, 'user.id'));
      if (userId && userId !== currentId) {
        this.loadUser.perform();
      }
    },
    // Tasks
    loadUser: (0, _emberConcurrency.task)(function* () {
      const id = this.userId;
      Ember.setProperties(this, {
        isLoaded: false,
        hasError: false
      });
      try {
        let user = yield this.store.peekRecord('user', id);
        if (Ember.isNone(user)) {
          user = yield this.store.findRecord('user', id);
        } else {
          Ember.debug(`Usuario ${id} presente no store`);
        }
        Ember.setProperties(this, {
          user,
          isLoaded: true
        });
      } catch (e) {
        Ember.debug(`<UserAttribute> :: ${e}`);
        return Ember.set(this, 'hasError', true);
      }
    }).drop()
  });
  _exports.default = _default;
});