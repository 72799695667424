define("pesquisa-talentrh/pods/application/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aAD/5tTN",
    "block": "{\"symbols\":[\"Menu\",\"Item\",\"Submenu\",\"module\"],\"statements\":[[5,\"ui-sidebar\",[],[[\"@title\",\"@linkTo\"],[\"Pesquisa\",\"home\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"item\"]],[],[[\"@icon\",\"@text\",\"@linkTo\",\"@showIf\"],[\"fa-poll\",\"Questionários\",\"survey-search\",[23,0,[\"showQuestionnaire\"]]]]],[0,\"\\n  \"],[6,[23,1,[\"item\"]],[],[[\"@icon\",\"@text\",\"@linkTo\",\"@showIf\"],[\"fa-file-signature\",\"Pesquisa Demissional\",\"survey-demissional\",[23,0,[\"showDemissionalQuestionnaire\"]]]]],[0,\"\\n  \"],[6,[23,1,[\"item\"]],[],[[\"@text\",\"@icon\"],[\"Módulos\",\"fa fa-th\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"submenu\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"modules\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,4,[\"type\"]],\"conta\"],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"canAccessAccountModule\"]]],null,{\"statements\":[[0,\"            \"],[6,[23,3,[\"item\"]],[],[[\"@text\",\"@link\"],[[23,4,[\"title\"]],[28,\"app-url\",[[23,4,[\"type\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[6,[23,3,[\"item\"]],[],[[\"@text\",\"@link\"],[[23,4,[\"title\"]],[28,\"app-url\",[[23,4,[\"type\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[4]},null],[0,\"    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/application/sidebar/template.hbs"
    }
  });
  _exports.default = _default;
});