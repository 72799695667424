define("pesquisa-talentrh/pods/result/answer/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    quiz: Ember.computed.alias('model'),
    filterOptions: Ember.A([{
      label: 'Todas',
      filter: 'selectAll'
    }, {
      label: 'Respondidas',
      filter: 'finished'
    }, {
      label: 'Não Respondidas',
      filter: 'pending'
    }]),
    init() {
      this._super(...arguments);
      this.selectedFilter = {
        label: 'Todas',
        filter: 'selectAll'
      };
    },
    filterFinished(selected) {
      Ember.set(this, 'finishedFilter', selected);
      this.getFilters();
    },
    getFilters() {
      let filters = {};
      filters.quiz = this.filters.quiz;
      if (this.finishedFilter.filter === 'finished') {
        filters.finished = 1;
      }
      if (this.finishedFilter.filter === 'pending') {
        filters.finished = 0;
      }
      Ember.set(this, 'filters', filters);
    }
  });
  _exports.default = _default;
});