define("pesquisa-talentrh/pods/components/survey-form/section/question/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    altSelected: null,
    altCheckbox: Ember.A(),
    alternatives: Ember.computed.mapBy('question.alternatives', 'label'),
    isInput: Ember.computed.equal('question.type', 'input'),
    isSelect: Ember.computed.equal('question.type', 'select'),
    isClassification: Ember.computed.equal('question.type', 'classification'),
    isLinearGrid: Ember.computed.equal('question.type', 'linearGrid'),
    isLikert: Ember.computed.equal('question.type', 'likert'),
    hasJustify: false,
    hasReason: Ember.computed('alternatives', 'question.answer.[]', function () {
      if (!this.question.answer || !this.question.answer.length) {
        return false;
      }
      let requireReason = this.question.alternatives.filterBy('reason');
      requireReason = requireReason.mapBy('label');
      if (Ember.isArray(this.question.answer)) {
        return this.question.answer.some(answer => {
          return requireReason.includes(answer);
        });
      }
      return requireReason.includes(this.question.answer);
    }),
    actions: {
      checkRatingStar(level) {
        let requireReason = this.question.alternatives.filterBy('reason');
        if (requireReason && requireReason[0] && requireReason[0].reason) {
          Ember.set(this, 'hasJustify', true);
        }
        if (level === 1 && level === this.question.answer) {
          Ember.set(this, 'question.answer', null);
          Ember.set(this, 'question.answerAux', null);
        } else {
          Ember.set(this, 'question.answer', String(level));
          let alternatives = [];
          for (let i = 0; i < this.question.level.value; i++) {
            let alternative = {
              label: String(i + 1),
              order: i
            };
            alternatives.push(alternative);
          }
          if (this.hasJustify) {
            alternatives[level - 1].reason = true;
          }
          Ember.set(this, 'question.alternatives', alternatives);
        }
      },
      checkRatingRadio(level) {
        let requireReason = this.question.alternatives.filterBy('reason');
        if (requireReason && requireReason[0] && requireReason[0].reason) {
          Ember.set(this, 'hasJustify', true);
        }
        Ember.set(this, 'question.answer', String(level + 1));
        Ember.set(this, 'question.answerAux', level);
        let alternatives = [];
        for (let i = 0; i < this.question.level.value; i++) {
          let alternative = {
            label: String(i + 1),
            order: i
          };
          alternatives.push(alternative);
        }
        if (this.hasJustify) {
          alternatives[level - 1].reason = true;
        }
        Ember.set(this, 'question.alternatives', alternatives);
      },
      checkRatingGrid(level) {
        let requireReason = this.question.alternatives.filterBy('reason');
        if (requireReason && requireReason[0] && requireReason[0].reason) {
          Ember.set(this, 'hasJustify', true);
        }
        if (level === this.question.answer) {
          Ember.set(this, 'question.answer', null);
        } else {
          Ember.set(this, 'question.answer', String(level));
          let alternatives = [{
            label: '0',
            order: 0
          }, {
            label: '1',
            order: 1
          }, {
            label: '2',
            order: 2
          }, {
            label: '3',
            order: 3
          }, {
            label: '4',
            order: 4
          }, {
            label: '5',
            order: 5
          }, {
            label: '6',
            order: 6
          }, {
            label: '7',
            order: 7
          }, {
            label: '8',
            order: 8
          }, {
            label: '9',
            order: 9
          }, {
            label: '10',
            order: 10
          }];
          if (this.hasJustify) {
            alternatives[level - 1].reason = true;
          }
          Ember.set(this, 'question.alternatives', alternatives);
        }
      },
      selectLikertAnswer() {
        let listElements = [];
        let listAnswer = [];
        let likertRows = this.question.likertRows;
        let likertColumns = this.question.likertColumns;
        likertRows.forEach((row, index) => {
          listElements.push(document.getElementsByName('likert' + row.order));
        });
        listElements.forEach(elements => {
          elements.forEach((element, index) => {
            if (element.checked) {
              listAnswer.push(index + 1);
            }
          });
        });
        let alternatives = [];
        for (let i = 0; i < likertColumns.length; i++) {
          let colum = likertColumns[i];
          let alternative = {
            label: String(colum.label),
            order: i + 1
          };
          alternatives.push(alternative);
        }
        Ember.set(this, 'question.alternatives', alternatives);
        Ember.set(this, 'question.answer', listAnswer);
      }
    }
  });
  _exports.default = _default;
});