define("pesquisa-talentrh/pods/result/final/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    quiz: Ember.computed.alias('model'),
    endpoint: Ember.computed('quiz.id', 'departments.length', 'users.length', function () {
      let quiz = Ember.get(this, 'quiz.id');
      let query = '';
      let departments = Ember.get(this, 'departments');
      if (departments && departments.length) {
        departments = departments.map(d => d.id);
        departments = _jquery.default.param({
          departments
        });
        query += `${departments}&`;
      }
      let users = Ember.get(this, 'users');
      if (users && users.length) {
        users = users.map(u => u.id);
        users = _jquery.default.param({
          users
        });
        query += `${users}`;
      }
      return `/quizAnswerItems/${quiz}/chartResultData?${query}`;
    })
  });
  _exports.default = _default;
});