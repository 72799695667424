define("pesquisa-talentrh/pods/result/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    admin: Ember.computed.alias('permission.accessProfile.admin'),
    allowManageSearches: Ember.computed.alias('permission.accessProfile.allowManageSearches'),
    allowCreateQuestionnaires: Ember.computed.alias('permission.accessProfile.allowCreateQuestionnaires'),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(_ref) {
      let {
        quiz_id
      } = _ref;
      const allowed = this.admin || this.allowManageSearches || this.allowCreateQuestionnaires;
      if (allowed) {
        this.store.query('quiz', {
          sort: 'updatedAt DESC'
        }).then(q => q.toArray()).then(q => q.map(q => q.id)).then(q => {
          if (!q.includes(quiz_id)) {
            return this.router.transitionTo('errors.forbidden');
          }
        });
        return this.store.findRecord('quiz', quiz_id);
      }
    }
  });
  _exports.default = _default;
});