define("pesquisa-talentrh/pods/home/recent/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bLGMgoC4",
    "block": "{\"symbols\":[\"Card\",\"@quiz\"],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"result.general\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"ui-card\",[[12,\"class\",\"text-dark home-recent\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"home-recent__status badge \",[22,\"statusClass\"]]]],[8],[0,\"\\n        \"],[1,[22,\"status\"],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"home-recent__color color-block--\",[23,2,[\"color\"]]]]],[8],[9],[0,\"\\n\\n      \"],[7,\"h5\",true],[10,\"class\",\"text-truncate mb-0\"],[8],[1,[23,2,[\"title\"]],false],[9],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n        Criado em: \"],[1,[28,\"moment-format\",[[23,2,[\"createdAt\"]],\"DD/MM/YYYY\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/home/recent/template.hbs"
    }
  });
  _exports.default = _default;
});