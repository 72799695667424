define("pesquisa-talentrh/pods/errors/forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FYbc7ZLD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"section-body contain-lg\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"text-black-50\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fa fa-meh-o\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"h2\",true],[10,\"class\",\"text-black-50\"],[8],[0,\"Ops! Você não tem autorização para ver esta página.\"],[9],[0,\"\\n        \"],[7,\"h3\",true],[10,\"class\",\"text-black-50\"],[8],[0,\"Fale com um administrador do sistema.\"],[9],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"home\"]],{\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[8],[0,\"Voltar\"],[9]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/errors/forbidden/template.hbs"
    }
  });
  _exports.default = _default;
});