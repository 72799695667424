define("pesquisa-talentrh/pods/survey/index/send/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fAVaraa3",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[5,\"ui-card\",[[12,\"class\",\"border-top-0\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-link\"],[10,\"title\",\"Clique para copiar\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"copy\"],null]],[8],[0,\"\\n      \"],[1,[22,\"link\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/survey/index/send/link/template.hbs"
    }
  });
  _exports.default = _default;
});