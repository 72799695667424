define("pesquisa-talentrh/pods/components/survey-form/section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['mb-4'],
    classNameBindings: ['isSectionActive::d-none'],
    isSectionActive: Ember.computed('section.index', 'activeSection', function () {
      return this.activeSection === `navSection${this.section.index}`;
    })
  });
  _exports.default = _default;
});