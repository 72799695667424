define("pesquisa-talentrh/pods/result/answer/item/component", ["exports", "ember-concurrency", "pesquisa-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    userAvatar: Ember.computed('quizAnswer', async function () {
      const user = await Ember.get(this.quizAnswer, 'user');
      const quiz = await Ember.get(this.quizAnswer, 'quiz');
      if (!user.active && quiz.quizType !== 'demissional') {
        return '/images/inactive-user.png';
      }
      if (!user.avatarUrl || quiz.anonymous) {
        return '/images/user-sem-imagem.png';
      }
      return `${_environment.default.bucketUrl}${user.avatarUrl}`;
    }),
    removeAnswer: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja remover as respostas?');
        if (!value) {
          return;
        }
        if (this.quizAnswer.anonymous) {
          yield this.quizAnswer.destroyRecord();
        } else {
          yield this.ajax.put(`quizAnswers/${this.quizAnswer.id}/removeAnswer`);
          Ember.setProperties(this.quizAnswer, {
            answers: Ember.get(this.quizAnswer, 'quiz.sections'),
            finished: false,
            timeCompletion: null
          });
        }
        this.toast.success('Respostas removidas');
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});