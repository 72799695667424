define("pesquisa-talentrh/pods/components/chart/canvas/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'canvas',
    chart: null,
    chartType: 'bar',
    options: null,
    didInsertElement() {
      this._super(...arguments);
      this.initChart();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      Ember.get(this, 'chart').destroy();
      this.initChart();
    },
    willDestroyElement() {
      this._super(...arguments);
      Ember.get(this, 'chart').destroy();
    },
    initChart() {
      let chart = new _chartJs.default(this.element, {
        type: Ember.get(this, 'type'),
        data: Ember.get(this, 'data'),
        options: Ember.get(this, 'options')
      });
      Ember.set(this, 'chart', chart);
    }
  });
  _exports.default = _default;
});