define("pesquisa-talentrh/nela-dataloader/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/nela-dataloader.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-dataloader.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-paging.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-paging.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-paging/btn-load-more.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-paging/btn-load-more.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-paging/empty.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-paging/empty.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/nela-paging/loading.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/nela-paging/loading.hbs should pass TemplateLint.\n\n');
  });
});