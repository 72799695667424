define("pesquisa-talentrh/pods/result/answer-multiple/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oaECcnSD",
    "block": "{\"symbols\":[\"@quizAnswer\"],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[5,\"ui-avatar\",[[12,\"class\",\"avatar-4 float-left\"],[12,\"alt\",[28,\"or\",[[23,1,[\"userName\"]],\"Anônimo\"],null]]],[[\"@src\"],[[23,0,[\"userAvatar\"]]]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"float-left ml-2\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[1,[28,\"or\",[[23,1,[\"userName\"]],\"Anônimo\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[1,[23,1,[\"departmentName\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"quizAnswer\",\"lastAnswer\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[28,\"moment-format\",[[23,1,[\"lastAnswer\"]],\"DD/MM/YYYY\"],null],false],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"small text-muted mb-0\"],[8],[0,\"\\n      \"],[1,[28,\"moment-format\",[[23,1,[\"lastAnswer\"]],\"HH:mm:ss\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-sm btn-outline-primary\"],[12,\"title\",\"Visualizar Repostas\"]],[[\"@route\",\"@models\"],[\"answer-view-user\",[28,\"array\",[[23,1,[\"quiz_id\"]],[23,1,[\"user_id\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"far fa-eye\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[10,\"class\",\"text-right\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"Não respondido\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/result/answer-multiple/item/template.hbs"
    }
  });
  _exports.default = _default;
});