define("pesquisa-talentrh/pods/components/survey-form/section/question/alternative/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['col-12', 'mb-2'],
    altSelected: null,
    isRadio: Ember.computed.equal('question.type', 'radio'),
    isCheckbox: Ember.computed.equal('question.type', 'checkbox'),
    isChecked: Ember.computed('question.answer.[]', function () {
      if (!this.question.answer) {
        return false;
      }
      return this.question.answer.includes(this.alternative);
    }),
    actions: {
      checkAnswer(selected) {
        if (!this.question.answer) {
          Ember.set(this, 'question.answer', Ember.A());
        }
        if (this.isChecked) {
          return this.question.answer.removeObject(selected);
        }
        this.question.answer.addObject(selected);
      },
      selectAnswer(selected) {
        Ember.set(this, 'altSelected', selected);
        Ember.set(this, 'question.answer', selected.label);
      }
    }
  });
  _exports.default = _default;
});