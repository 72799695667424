define("pesquisa-talentrh/pods/application/about/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5IRcaHL3",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[5,\"ui-modal\",[],[[\"@open\"],[[22,\"openModal\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"Pesquisa\"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"Pesquisa!\"],[9],[0,\"\\n\\n      \"],[7,\"p\",true],[8],[0,\"\\n        É um projeto desenvolvido pela equipe Voors, que produz softwares\\n        especializados e voltados para a gestão de pessoas,\\n        \"],[7,\"a\",true],[10,\"href\",\"https://nela.com.br\"],[10,\"class\",\"text-blue\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n          clique aqui\\n        \"],[9],[0,\"\\n        para saber mais informações!\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"p\",true],[10,\"class\",\"text-bold\"],[8],[0,\"Versão\"],[9],[0,\"\\n      \"],[1,[28,\"app-version\",null,[[\"hideSha\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"login-bottom text-center mt-1\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"http://www.viasoft.com.br\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"w-50\"],[10,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/viasoft_logo_solucao.png\"],[10,\"alt\",\"Viasoft\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/application/about/template.hbs"
    }
  });
  _exports.default = _default;
});