define("pesquisa-talentrh/pods/answer-view-user/controller", ["exports", "ember-concurrency", "pesquisa-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    quiz: Ember.computed.alias('model.quiz'),
    user: Ember.computed.alias('model.user'),
    quizAnswersList: Ember.computed.alias('model.quizAnswersList'),
    sortingKey: Ember.A(['createdAt:desc']),
    quizAnswer: Ember.computed.alias('model'),
    quizAnswerOrdered: Ember.computed.sort('quizAnswersList', 'sortingKey'),
    userAvatar: Ember.computed('quizAnswer', function () {
      if (!this.quizAnswer.active) {
        return '/images/inactive-user.png';
      }
      if (!this.quizAnswer.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      return `${_environment.default.bucketUrl}${this.quizAnswer.avatarUrl}`;
    }),
    removeAnswer: (0, _emberConcurrency.task)(function* (id) {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja remover as respostas?');
        if (!value) {
          return;
        }
        const quizAnswer = yield this.store.findRecord('quizAnswer', id);
        yield quizAnswer.destroyRecord();
        this.toast.success('Respostas removidas');
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});