define("pesquisa-talentrh/pods/survey/form/color-palette/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['dropdown', 'float-right'],
    colorPalette: Ember.A([{
      value: 'blue'
    }, {
      value: 'warning'
    }, {
      value: 'green'
    }, {
      value: 'navy'
    }, {
      value: 'orange'
    }, {
      value: 'olive'
    }, {
      value: 'purple'
    }, {
      value: 'red'
    }, {
      value: 'dark'
    }])
  });
  _exports.default = _default;
});