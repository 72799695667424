define("pesquisa-talentrh/pods/survey/index/send/users/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['tab-pane', 'fade', 'show', 'active', 'survey-send-users'],
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    allSelected: false,
    toggleSearch: false,
    selectAllEmployed: false,
    sort: 'firstName ASC',
    usersSelected: Ember.computed.mapBy('quizAnswers', 'user'),
    filterText: Ember.computed('filters', function () {
      let str;
      if (this.filters.or || this.filters.department || this.filters.jobRole || this.filters.branch || this.filters.startDate || this.filters.endDate) {
        str = 'Selecionar todos os colaboradores filtrados';
      } else {
        str = 'Selecionar todos os colaboradores';
      }
      return str;
    }),
    init() {
      this._super(...arguments);
      this.filters = {
        active: 1,
        quiz: this.quiz.id
      };
      if (this.quiz.quizType === 'demissional' && this.quiz.causes) {
        this.filters.causes = this.quiz.causes.map(c => c.id);
      }
    },
    filterName: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable(),
    removeQuizAnswer: (0, _emberConcurrency.task)(function* (quizAnswer) {
      try {
        if (quizAnswer && quizAnswer.id) {
          yield quizAnswer.destroyRecord();
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    selectAll: (0, _emberConcurrency.task)(function* () {
      this.toggleProperty('selectAllEmployed');
      try {
        if (this.selectAllEmployed) {
          this.swal.loading('Selecionando usuários');
          let users;
          let filters = {};
          if (this.nameFilter) {
            filters.term = this.nameFilter;
          }
          if (this.filters.department) {
            filters.department = this.filters.department;
          }
          if (this.filters.jobRole) {
            filters.jobRole = this.filters.jobRole;
          }
          if (this.filters.or) {
            filters.or = this.filters.or;
          }
          if (this.filters.startDate) {
            filters.startDate = this.startDateFilter;
          }
          if (this.filters.endDate) {
            filters.endDate = this.endDateFilter;
          }
          if (this.filters.branch) {
            filters.branch = this.filters.branch;
          }
          if (this.quiz.quizType === 'demissional') {
            filters.quiz = this.quiz.id;
            if (this.quiz.causes) {
              filters.causes = this.quiz.causes.map(c => c.id);
            }
            users = yield this.store.loadRecords('user', 'users/findFired', {
              ...filters,
              noLimit: true
            });
          } else {
            users = yield this.store.loadRecords('user', 'users/ajax', {
              ...filters,
              noLimit: true
            });
          }
          let usersToSelect = [];
          let i = 0,
            len = users.length;
          while (i < len) {
            const user = users[i];
            let quizAnswer = this.quizAnswers.findBy('user.id', user.id);
            if (!quizAnswer) {
              usersToSelect.push({
                user
              });
            }
            i++;
          }
          this.quizAnswers.addObjects(usersToSelect);
        } else {
          if (this.quiz.quizType !== 'demissional') {
            let quizAnswers = this.quizAnswers;
            let finished = this.quizAnswers.findBy('finished', true);
            if (finished) {
              let {
                value
              } = yield this.swal.confirm('Confirmar remoção dos usuários?', {
                text: 'As respostas destes usuários serão removidas'
              });
              if (!value) {
                return;
              }
            }
            this.swal.loading('Removendo usuários');
            yield Ember.RSVP.map(quizAnswers, quizAnswer => {
              if (quizAnswer && quizAnswer.id) {
                return quizAnswer.destroyRecord();
              }
            });
          }
          this.quizAnswers.clear();
        }
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Custom methods
    getFilters() {
      let filters = {
        active: 1,
        quiz: this.quiz.id
      };
      if (this.departmentFilter && this.departmentFilter.length) {
        filters.department = this.departmentFilter.map(d => d.id);
      } else {
        this.set('selectAllEmployed', 0);
      }
      if (this.jobRoleFilter && this.jobRoleFilter.length) {
        filters.jobRole = this.jobRoleFilter.map(jf => jf.id);
      } else {
        this.set('selectAllEmployed', 0);
      }
      if (this.branchFilter && this.branchFilter.length) {
        filters.branch = this.branchFilter.map(b => b.id);
      } else {
        this.set('selectAllEmployed', 0);
      }
      if (this.nameFilter) {
        filters.term = this.nameFilter;
      } else {
        this.set('selectAllEmployed', 0);
      }
      if (this.startDateFilter) {
        filters.startDate = this.startDateFilter;
      }
      if (this.endDateFilter) {
        filters.endDate = this.endDateFilter;
      }
      if (this.quiz.quizType === 'demissional' && this.quiz.causes) {
        filters.quiz = this.quiz.id;
        filters.causes = this.filters.causes = this.quiz.causes.map(c => c.id);
      }
      Ember.set(this, 'filters', filters);
    },
    actions: {
      clearFilters() {
        let filters = {
          quiz: this.quiz.id
        };
        if (this.quiz.quizType === 'demissional' && this.quiz.causes) {
          filters.quiz = this.quiz.id;
          filters.causes = this.quiz.causes.map(c => c.id);
          filters.active = 0;
        } else {
          filters.active = 1;
        }
        Ember.setProperties(this, {
          branchFilter: null,
          departmentFilter: null,
          filters,
          jobRoleFilter: null,
          nameFilter: null,
          selectAllEmployed: 0,
          startDateFilter: null,
          endDateFilter: null
        });
      },
      filterDepartment(selected) {
        Ember.set(this, 'departmentFilter', selected);
        this.getFilters();
      },
      filterJobRole(selected) {
        Ember.set(this, 'jobRoleFilter', selected);
        this.getFilters();
      },
      filterBranch(selected) {
        Ember.set(this, 'branchFilter', selected);
        this.getFilters();
      },
      filterStartDate(selected) {
        if (this.endDateFilter && selected) {
          if ((0, _moment.default)(selected).isAfter((0, _moment.default)(this.endDateFilter))) {
            return this.swal.warning('A data inicial não pode ser maior que a data final.');
          }
        }
        Ember.set(this, 'startDateFilter', selected);
        this.getFilters();
      },
      filterEndDate(selected) {
        if (this.startDateFilter && selected) {
          if ((0, _moment.default)(selected).isBefore((0, _moment.default)(this.startDateFilter))) {
            return this.swal.warning('A data final não pode ser menor que a data inicial.');
          }
        }
        Ember.set(this, 'endDateFilter', selected);
        this.getFilters();
      },
      toggleSearch() {
        this.toggleProperty('toggleSearch');
        if (!this.toggleSearch) {
          this.send('clearFilters');
        }
      }
    }
  });
  _exports.default = _default;
});