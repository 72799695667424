define("pesquisa-talentrh/pods/home/recent/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    status: Ember.computed('quiz.status', function () {
      if (this.quiz.status === 'A') {
        return 'Aberto';
      }
      if (this.quiz.status === 'F') {
        return 'Fechado';
      }
      if (this.quiz.status === 'R') {
        return 'Rascunho';
      }
      return;
    }),
    statusClass: Ember.computed('quiz.status', function () {
      if (this.quiz.status === 'A') {
        return 'bg-success';
      }
      if (this.quiz.status === 'F') {
        return 'bg-danger';
      }
      if (this.quiz.status === 'R') {
        return 'bg-warning';
      }
      return;
    })
  });
  _exports.default = _default;
});