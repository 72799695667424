define("pesquisa-talentrh/pods/result/final/filters/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N9b3a3nk",
    "block": "{\"symbols\":[\"@icon\",\"@text\"],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"fa \",[23,1,[]]]]],[8],[9],[0,\"\\n\"],[1,[23,2,[]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/result/final/filters/trigger/template.hbs"
    }
  });
  _exports.default = _default;
});