define("pesquisa-talentrh/services/permission", ["exports", "ember-concurrency", "pesquisa-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    // Default values
    accessProfile: null,
    companyConfig: null,
    isLoaded: false,
    user: null,
    admin: Ember.computed.alias('accessProfile.admin'),
    // Tasks
    load: (0, _emberConcurrency.task)(function* () {
      const session = Ember.get(this, 'session');
      try {
        if (!Ember.get(session, 'isAuthenticated')) {
          return {};
        }
        if (!Ember.get(session, 'user')) {
          yield session.loadMe.perform();
        }
        yield (0, _emberConcurrency.all)([this.loadAccessProfile.perform(), this.loadCompanyConfig.perform(), this.loadUser.perform()]);
        if (!this.admin && (!this.companyConfig.hasPesquisaModule || !this.accessProfile.allowAccessSearches)) {
          yield this.swal.warning('Entre em contato com seu administrador do sistema para mais informações', {
            title: 'Você não tem acesso a este módulo'
          });
          return document.location.href = _environment.default.appUrl.social;
        }
        Ember.set(this, 'isLoaded', true);
        return {
          accessProfile: Ember.get(this, 'accessProfile'),
          companyConfig: Ember.get(this, 'companyConfig'),
          user: Ember.get(this, 'user')
        };
      } catch (e) {
        Ember.debug(`Permission service :: load :: ${e}`);
        Ember.get(this, 'swal').catchMessage(e);
      }
    }),
    loadAccessProfile: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          accessProfile
        } = yield this.ajax.request('accessProfiles/me');
        Ember.set(this, 'accessProfile', accessProfile);
      } catch (e) {
        Ember.debug(`Permission service :: loadAccessProfile :: ${e}`);
        Ember.get(this, 'swal').catchMessage(e);
      }
    }).restartable(),
    loadCompanyConfig: (0, _emberConcurrency.task)(function* () {
      const session = Ember.get(this, 'session');
      const company = session.get('user.company');
      try {
        const companyConfiguration = yield this.store.findRecord('companyConfiguration', company);
        Ember.set(this, 'companyConfig', companyConfiguration);
      } catch (e) {
        Ember.debug(`Permission service :: loadCompanyConfig :: ${e}`);
        Ember.get(this, 'swal').catchMessage(e);
      }
    }).restartable(),
    loadUser: (0, _emberConcurrency.task)(function* () {
      const session = Ember.get(this, 'session');
      const userId = session.get('user.id');
      try {
        const user = yield this.store.findRecord('user', userId);
        Ember.set(this, 'user', user);
      } catch (e) {
        Ember.debug(`Permission service :: loadUser :: ${e}`);
        Ember.get(this, 'swal').catchMessage(e);
      }
    }).restartable()
  });
  _exports.default = _default;
});