define("pesquisa-talentrh/pods/components/survey-form/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "loNgwRHa",
    "block": "{\"symbols\":[\"question\",\"Card\",\"@section\"],\"statements\":[[4,\"if\",[[23,3,[\"description\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ui-card\",[[12,\"class\",\"shadow-sm mb-3\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"p\",true],[10,\"style\",\"white-space: pre-line;\"],[8],[0,\"\\n        \"],[1,[23,3,[\"description\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,3,[\"questions\"]]],null,{\"statements\":[[0,\"  \"],[5,\"survey-form/section/question\",[],[[\"@question\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/components/survey-form/section/template.hbs"
    }
  });
  _exports.default = _default;
});