define("pesquisa-talentrh/utils/quiz", ["exports", "sweet-alert2", "moment"], function (_exports, _sweetAlert, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = quiz;
  function quiz(model) {
    let configEdit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const minDate = (0, _moment.default)();
    let msg = '';
    let result = true;
    if (!model.get('title')) {
      msg += 'Informe o Título do Questionário<br>';
      result = false;
    }
    if (!configEdit && model.get('validity') && minDate.isAfter((0, _moment.default)(model.get('validity')), 'date') && model.get('quizType') !== 'demissional') {
      msg += 'A Validade do Questionário não pode ser menor que a data atual<br>';
      result = false;
    }
    if (model.get('quizType') !== 'demissional' && !model.get('public') && !model.get('anonymous')) {
      msg += 'Informe o Tipo de Pesquisa<br>';
      result = false;
    }
    if (model.get('quizType') === 'demissional' && !model.get('daysToAnswer')) {
      msg += 'Informe a quantidade de dias para responder<br>';
      result = false;
    }
    if (model.get('autoSend') && (!model.get('sendType') || !model.get('sendType.length'))) {
      msg += 'Informe a forma de envio<br>';
      result = false;
    }
    if (model.get('autoSend') && (!model.get('causes') || !model.get('causes').length)) {
      msg += 'Informe as causas de demissão<br>';
      result = false;
    }
    if (!model.get('sections.length')) {
      msg += 'Informe ao menos uma Página do Questionário<br>';
      result = false;
    } else {
      model.get('sections').forEach(page => {
        if (!page.questions.length) {
          msg += 'Informe ao menos uma Pergunta<br>';
          result = false;
        } else {
          page.questions.forEach(question => {
            if (!question.title) {
              msg += 'Informe o Título da Pergunta<br>';
              result = false;
            }
            if (!question.alternatives.length) {
              msg += `Informe ao menos uma Alternativa para a Pergunta ${question.title}<br>`;
              result = false;
            } else {
              question.alternatives.forEach(alternative => {
                if (!alternative.label && question.type !== 'input' && question.type !== 'title' && question.type !== 'classification' && question.type !== 'linearGrid' && question.type !== 'likert') {
                  const title = question.title || "";
                  msg += `Informe uma Descrição para a Alternativa da Pergunta ${title}<br>`;
                  result = false;
                }
              });
              if (question.type === 'linearGrid' && (!question.label1 || !question.label2)) {
                const title = question.title || "";
                msg += `Informe os Rótulos para a Pergunta ${title}<br>`;
                result = false;
              }
            }
          });
        }
      });
    }
    if (!result) {
      _sweetAlert.default.fire({
        title: 'Ops!',
        html: msg,
        icon: 'warning'
      });
    }
    return result;
  }
});