define("pesquisa-talentrh/pods/application/super-user-login/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    ajaxAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    openModal: false,
    superUserLogin: (0, _emberConcurrency.task)(function* () {
      try {
        this.session.set('data.keybkp', this.session.data.authenticated);
        yield this.session.authenticate('authenticator:super-user', {
          identification: this.userIdentifier
        });
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});