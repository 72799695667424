define("pesquisa-talentrh/helpers/app-url", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appUrl = appUrl;
  _exports.default = void 0;
  function appUrl(params /*, hash*/) {
    return _environment.default.appUrl[params[0]] + (params[1] || '');
  }
  var _default = Ember.Helper.helper(appUrl);
  _exports.default = _default;
});