define("pesquisa-talentrh/pods/answer-view-user/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      return Ember.RSVP.hash({
        quiz: this.store.findRecord('quiz', params.quiz_id),
        user: this.store.findRecord('user', params.user_id),
        quizAnswersList: this.store.query('quizAnswer', {
          user: params.user_id,
          quiz: params.quiz_id
        })
      });
    }
  });
  _exports.default = _default;
});