define("pesquisa-talentrh/pods/components/survey-form/navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['survey-answer__nav'],
    hasOverflow: false,
    didRender() {
      this._super(...arguments);
      let card = document.getElementById('survey-navigation');
      if (card.scrollWidth > card.offsetWidth) {
        Ember.set(this, 'hasOverflow', true);
        card.style.padding = '0 32px';
      }
      document.getElementById(this.activeSection).classList.add('active');
    },
    actions: {
      moveLeft() {
        let nav = document.getElementById('survey-navigation');
        let elements = nav.getElementsByTagName('div');
        elements = Ember.A(Array.from(elements));
        elements = elements.map(item => {
          if (item.offsetLeft < nav.scrollLeft) {
            return item;
          }
        }).compact();
        if (!elements || !elements.length) {
          return;
        }
        elements.lastObject.scrollIntoView({
          block: 'nearest',
          inline: 'start'
        });
        nav.scrollBy(-32, 0);
      },
      moveRight() {
        let nav = document.getElementById('survey-navigation');
        let scrollRight = nav.scrollLeft + nav.offsetWidth;
        let elements = nav.getElementsByTagName('div');
        elements = Ember.A(Array.from(elements));
        elements = elements.map(item => {
          let offsetRight = item.offsetLeft + item.offsetWidth - 32;
          if (offsetRight > scrollRight) {
            return item;
          }
        }).compact();
        if (!elements || !elements.length) {
          return;
        }
        elements.firstObject.scrollIntoView({
          block: 'nearest',
          inline: 'end'
        });
        nav.scrollBy(32, 0);
      },
      selectSection(index) {
        let id = `navSection${index}`;
        if (id !== this.activeSection) {
          document.getElementById(this.activeSection).classList.remove('active');
          let element = document.getElementById(id);
          element.classList.add('active');
          Ember.set(this, 'activeSection', id);
        }
      }
    }
  });
  _exports.default = _default;
});