define("pesquisa-talentrh/pods/answer-completed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AW67whGe",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"color-block--\",[24,[\"quiz\",\"color\"]],\" shadow-sm t-0 px-3 py-4 py-lg-5 \"]]],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"text-white text-center my-0 mt-lg-2\"],[8],[0,\"\\n    \"],[1,[24,[\"quiz\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n      \"],[5,\"ui-card\",[[12,\"class\",\"shadow-sm\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"body\"]],[[12,\"class\",\"text-center\"]],[[],[]],{\"statements\":[[0,\"\\n          \"],[7,\"h3\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n          \"],[7,\"h6\",true],[8],[1,[22,\"subtitle\"],false],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"watermark\",[[12,\"class\",\"position-absolute b-2\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"text-primary\"],[8],[0,\"Pesquisa\"],[9],[0,\"\\n  é uma solução:\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/answer-completed/template.hbs"
    }
  });
  _exports.default = _default;
});