define("pesquisa-talentrh/helpers/s3-url", ["exports", "pesquisa-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.s3Url = s3Url;
  function s3Url(params /*, hash*/) {
    if (params[0] && params[0].includes('gravatar')) {
      return params[0];
    }
    return _environment.default.s3Bucket + params[0];
  }
  var _default = Ember.Helper.helper(s3Url);
  _exports.default = _default;
});