define("pesquisa-talentrh/pods/survey/form/question/component", ["exports", "ember-concurrency", "jquery"], function (_exports, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['survey-form__question', 'px-3'],
    swal: Ember.inject.service(),
    description: false,
    other: false,
    typeOptions: Ember.A([{
      label: 'Parágrafo',
      value: 'input'
    }, {
      label: 'Lista suspensa',
      value: 'select'
    }, {
      label: 'Resposta única',
      value: 'radio'
    }, {
      label: 'Múltipla escolha',
      value: 'checkbox'
    }, {
      label: 'Classificação',
      value: 'classification'
    }, {
      label: 'Grade Linear',
      value: 'linearGrid'
    }, {
      label: 'Likert',
      value: 'likert'
    }]),
    classificationLevels: Ember.A([{
      label: '2',
      value: 2
    }, {
      label: '3',
      value: 3
    }, {
      label: '4',
      value: 4
    }, {
      label: '5',
      value: 5
    }, {
      label: '6',
      value: 6
    }, {
      label: '7',
      value: 7
    }, {
      label: '8',
      value: 8
    }, {
      label: '9',
      value: 9
    }, {
      label: '10',
      value: 10
    }]),
    likertColumns: Ember.A([{
      label: 'Coluna 1'
    }, {
      label: 'Coluna 2'
    }]),
    likertRows: Ember.A([{
      label: 'Linha 1'
    }, {
      label: 'Linha 2'
    }]),
    classificationSimbols: Ember.A([{
      label: 'Estrelas',
      value: 'stars'
    }, {
      label: 'Números',
      value: 'numbers'
    }]),
    enableAddOther: Ember.computed('question.type', 'other', function () {
      return Ember.get(this, 'question.type') === 'checkbox' && !this.other;
    }),
    enableAddAlternative: Ember.computed('question.type', 'other', function () {
      return this.question.type !== 'input' && this.question.type !== 'classification' && this.question.type !== 'linearGrid' && this.question.type !== 'likert';
    }),
    enableItemInput: Ember.computed('question.type', 'other', function () {
      return this.question.type !== 'classification' && this.question.type !== 'linearGrid' && this.question.type !== 'likert';
    }),
    isCheckbox: Ember.computed('question.type', function () {
      return this.question.type === 'checkbox';
    }),
    isRadio: Ember.computed('question.type', function () {
      return this.question.type === 'radio';
    }),
    isSelect: Ember.computed('question.type', function () {
      return this.question.type === 'select';
    }),
    isClassification: Ember.computed('question.type', function () {
      return this.question.type === 'classification';
    }),
    isLinearGrid: Ember.computed('question.type', function () {
      return this.question.type === 'linearGrid';
    }),
    isLikert: Ember.computed('question.type', function () {
      return this.question.type === 'likert';
    }),
    init() {
      this._super(...arguments);
      this.typeSelected = {
        label: 'Resposta única',
        value: 'radio'
      };
      this.likertColumns = [{
        label: 'Coluna 1'
      }, {
        label: 'Coluna 2'
      }];
      this.likertRows = [{
        label: 'Linha 1',
        order: Math.random()
      }, {
        label: 'Linha 2',
        order: Math.random()
      }];
      if (this.question && this.question.type === 'classification') {
        Ember.set(this, 'levelSelected', this.question.level);
        Ember.set(this, 'simbolSelected', this.question.simbol);
      } else {
        this.levelSelected = {
          label: '5',
          value: 5,
          default: true
        };
        this.simbolSelected = this.question && this.question.simbol && this.question.simbol.value ? this.question.simbol : {
          label: 'Estrelas',
          value: 'stars'
        };
      }
    },
    didInsertElement() {
      this._super(...arguments);
      let typeSelected = this.typeOptions.find(o => o.value === this.question.type);
      Ember.setProperties(this, {
        typeSelected,
        description: Boolean(this.question.description)
      });
    },
    click() {
      (0, _jquery.default)('.survey-form__question--active').removeClass('survey-form__question--active');
      (0, _jquery.default)(this.element).addClass('survey-form__question--active');
    },
    deleteQuestion: (0, _emberConcurrency.task)(function* () {
      let {
        value
      } = yield this.swal.confirm('Deseja excluir esta questão?');
      if (!value) {
        return;
      }
      Ember.get(this, 'questions').removeObject(this.question);
    }),
    changeAlternatives() {
      let alternatives = [];
      for (let i = 0; i < this.question.level.value; i++) {
        let alternative = {
          label: String(i + 1),
          order: i
        };
        alternatives.push(alternative);
      }
      Ember.set(this, 'question.alternativesAux', alternatives);
    },
    changeAlternativesLikert() {
      let alternatives = [];
      for (let i = 0; i < this.question.likertColumns.length; i++) {
        let colum = this.question.likertColumns[i];
        let alternative = {
          label: String(colum.label),
          order: i + 1
        };
        alternatives.push(alternative);
      }
      Ember.set(this, 'question.alternativesAux', alternatives);
    },
    actions: {
      addAlternative() {
        let alternatives = Ember.get(this, 'question.alternatives');
        alternatives.pushObject({
          label: null,
          order: alternatives.length
        });
      },
      addOther() {
        let alternatives = Ember.get(this, 'question.alternatives');
        alternatives.pushObject({
          label: null,
          order: alternatives.length,
          reason: true
        });
        Ember.set(this, 'other', true);
      },
      changeType(selected) {
        let value = Ember.get(selected, 'value');
        Ember.set(this, 'typeSelected', selected);
        Ember.set(this, 'question.simbol', []);
        Ember.set(this, 'question.likertColumns', []);
        Ember.set(this, 'question.likertRows', []);
        if (value == 'input' || value == 'checkbox') {
          Ember.get(this, 'question.alternatives').clear();
          Ember.get(this, 'question.alternatives').pushObject({
            label: null,
            order: 0
          });
        }
        if (value === 'classification') {
          Ember.get(this, 'question.alternatives').clear();
          Ember.get(this, 'question.alternatives').pushObject({
            label: null,
            order: 0
          });
          if (!this.question.simbol || !this.question.simbol.value) {
            this.question.simbol = {
              label: 'Estrelas',
              value: 'stars'
            };
          }
          if (!this.question.level || !this.question.level.value) {
            this.question.level = {
              label: '5',
              value: 5,
              default: true
            };
          }
          this.changeAlternatives();
        }
        if (value === 'linearGrid') {
          Ember.get(this, 'question.alternatives').clear();
          Ember.get(this, 'question.alternatives').pushObject({
            label: null,
            order: 0
          });
          let alternatives = [{
            label: '0',
            order: 0
          }, {
            label: '1',
            order: 1
          }, {
            label: '2',
            order: 2
          }, {
            label: '3',
            order: 3
          }, {
            label: '4',
            order: 4
          }, {
            label: '5',
            order: 5
          }, {
            label: '6',
            order: 6
          }, {
            label: '7',
            order: 7
          }, {
            label: '8',
            order: 8
          }, {
            label: '9',
            order: 9
          }, {
            label: '10',
            order: 10
          }];
          Ember.set(this, 'question.alternativesAux', alternatives);
        }
        if (value === 'likert') {
          Ember.get(this, 'question.alternatives').clear();
          Ember.get(this, 'question.alternatives').pushObject({
            label: null,
            order: 0
          });
          if (!this.question.likertColumns || !this.question.likertColumns.label) {
            Ember.set(this, 'question.likertColumns', this.likertColumns);
          }
          if (!this.question.likertRows || !this.question.likertRows.label) {
            Ember.set(this, 'question.likertRows', [{
              label: 'Linha 1',
              order: Math.random()
            }, {
              label: 'Linha 2',
              order: Math.random()
            }]);
          }
          this.changeAlternativesLikert();
        }
        Ember.set(this, 'question.type', value);
      },
      changeClassificationLevel(selected) {
        Ember.set(this, 'levelSelected', selected);
        Ember.set(this, 'question.level', selected);
        this.changeAlternatives();
      },
      changeClassificationSimbol(selected) {
        Ember.set(this, 'simbolSelected', selected);
        Ember.set(this, 'question.simbol', selected);
      },
      addLikertRow() {
        let likertRows = Ember.get(this, 'question.likertRows');
        const lastIndex = likertRows.length - 1;
        const newIndex = lastIndex + 1;
        const newRow = {
          label: `Linha ${newIndex + 1}`,
          order: Math.random()
        };
        likertRows.pushObject(newRow);
        this.changeAlternativesLikert();
      },
      removeLikertRow(index) {
        let likertRows = Ember.get(this, 'question.likertRows');
        likertRows.removeAt(index);
        this.changeAlternativesLikert();
      },
      addLikertColumn() {
        let likertColumns = Ember.get(this, 'question.likertColumns');
        const lastIndex = likertColumns.length - 1;
        const newIndex = lastIndex + 1;
        const newColumn = {
          label: `Coluna ${newIndex + 1}`
        };
        likertColumns.pushObject(newColumn);
        this.changeAlternativesLikert();
      },
      removeLikertColumn(index) {
        let likertColumns = Ember.get(this, 'question.likertColumns');
        likertColumns.removeAt(index);
        this.changeAlternativesLikert();
      },
      removeAlternative(item) {
        let alternatives = Ember.get(this, 'question.alternatives');
        if (item.reason) {
          Ember.set(this, 'other', false);
        }
        alternatives.removeObject(item);
      },
      duplicateQuestion() {
        let alternatives = this.question.alternatives.map(alt => {
          return {
            label: alt.label,
            order: alt.order,
            reason: alt.reason
          };
        });
        let likertRows = [];
        let likertColumns = [];
        if (this.question.type === 'likert') {
          this.question.likertRows.forEach(row => {
            let rowAux = {
              label: row.label,
              order: Math.random()
            };
            likertRows.push(rowAux);
          });
          this.question.likertColumns.forEach(colum => {
            let columAux = {
              label: colum.label,
              order: Math.random()
            };
            likertColumns.push(columAux);
          });
        }
        let question = {
          alternatives,
          description: this.question.description,
          order: this.question.length,
          required: this.question.required,
          title: this.question.title,
          type: this.question.type,
          level: this.question.level,
          simbol: this.question.simbol,
          label1: this.question.label1,
          label2: this.question.label2,
          likertRows: likertRows,
          likertColumns: likertColumns,
          alternativesAux: this.question.alternativesAux
        };
        this.questions.pushObject(question);
      },
      toggleDescription() {
        this.toggleProperty('description');
        let description = Ember.get(this, 'description');
        if (!description) {
          Ember.set(this, 'question.description', null);
        }
      }
    }
  });
  _exports.default = _default;
});