define("pesquisa-talentrh/pods/components/chart/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    chartType: 'bar',
    init() {
      this._super(...arguments);
      this.chartOptions = {
        responsive: true,
        // Instruct chartJS to respond nicely
        tooltips: {
          enabled: true,
          mode: 'label',
          callbacks: {
            title(tooltipItems, data) {
              let index = tooltipItems[0].index;
              return data.labels[index];
            },
            label(tooltipItem, data) {
              let label = tooltipItem.value || data.datasets[0].data[tooltipItem.index];
              if (data.symbol) {
                label += data.symbol;
              }
              return label;
            }
          }
        }
      };
      this.barChartOptions();
    },
    barChartOptions() {
      let chartOptions = Ember.get(this, 'chartOptions');
      Ember.setProperties(chartOptions, {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              callback(value) {
                if (value && value.length > 10) {
                  value = `${value.substr(0, 10)}...`;
                }
                return value;
              }
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0
            }
          }]
        }
      });
    },
    actions: {
      showBarChart() {
        this.barChartOptions();
        Ember.set(this, 'chartType', 'bar');
      },
      showPieChart() {
        let chartOptions = Ember.get(this, 'chartOptions');
        Ember.setProperties(chartOptions, {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
              generateLabels(chart) {
                let meta = chart.getDatasetMeta(0);
                let labels = chart.data.labels;
                let [dataset] = chart.data.datasets;
                if (!labels || !dataset) {
                  return [];
                }
                return labels.map((label, index) => {
                  let arcOpts = chart.options.elements.arc;
                  let text = label.length > 10 ? `${label.substr(0, 10)}...` : label;
                  let fillStyle = _chartJs.default.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, index, arcOpts.backgroundColor);
                  let strokeStyle = _chartJs.default.helpers.getValueAtIndexOrDefault(dataset.borderColor, index, arcOpts.borderColor);
                  let lineWidth = _chartJs.default.helpers.getValueAtIndexOrDefault(dataset.borderWidth, index, arcOpts.borderWidth);
                  return {
                    fillStyle,
                    index,
                    lineWidth,
                    strokeStyle,
                    text,
                    hidden: !dataset.data[index] || isNaN(dataset.data[index]) || meta.data[index].hidden
                  };
                });
              }
            }
          },
          scales: {}
        });
        Ember.set(this, 'chartType', 'pie');
      }
    }
  });
  _exports.default = _default;
});