define("pesquisa-talentrh/pods/survey/duplicate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(params) {
      this.store.query('quiz', {
        sort: 'updatedAt DESC'
      }).then(q => q.toArray()).then(q => q.map(q => q.id)).then(q => {
        if (!q.includes(params.id)) {
          return this.router.transitionTo('home');
        }
      });
      const quiz = this.store.findRecord('quiz', params.id).then(quiz => {
        let title = Ember.get(quiz, 'title');
        let formatedTitle = title.includes(' - cópia') ? title : title + ' - cópia';
        return quiz.copy(false, {
          ignoreAttributes: ['answers', 'status', 'sentQuiz', 'user', 'validity', 'createdAt', 'updatedAt'],
          overwrite: {
            title: formatedTitle
          }
        });
      });
      const session = Ember.get(this, 'session');
      const company = session.get('user.company');
      return Ember.RSVP.hash({
        quiz,
        companyConfiguration: this.store.findRecord('companyConfiguration', company)
      });
    },
    deactivate() {
      this.controller.model.quiz.rollbackAttributes();
    }
  });
  _exports.default = _default;
});