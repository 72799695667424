define("pesquisa-talentrh/pods/model-quiz-demissional/color-palette/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YW4PjLIR",
    "block": "{\"symbols\":[\"color\"],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-link text-white\"],[10,\"id\",\"colorPaletteDemissionalModel\"],[10,\"title\",\"Selecionar cor\"],[10,\"data-toggle\",\"dropdown\"],[10,\"aria-haspopup\",\"true\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fas fa-paint-brush fa-lg\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"dropdown-menu dropdown-menu-right color-palette\"],[10,\"aria-labelledby\",\"colorPalette\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"colorPalette\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[11,\"class\",[29,[\"btn color-palette__color color-block--\",[23,1,[\"value\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedColor\"]]],null],[23,1,[\"value\"]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"value\"]],[24,[\"selectedColor\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"fa fa-check text-white\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/model-quiz-demissional/color-palette/template.hbs"
    }
  });
  _exports.default = _default;
});