define("pesquisa-talentrh/services/ajax-account", ["exports", "ember-ajax/services/ajax", "pesquisa-talentrh/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.apiUrl.conta,
    headers: Ember.computed('session.token', {
      get() {
        let headers = {};
        if (this.session.token) {
          headers['Authorization'] = `Bearer ${this.session.token}`;
        }
        return headers;
      }
    })
  });
  _exports.default = _default;
});