define("pesquisa-talentrh/pods/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentPage: 'login',
    url: Ember.computed.alias('model'),
    actions: {
      authenticate() {
        if (this.url) {
          this.transitionToRoute('answer', this.url);
        }
      }
    }
  });
  _exports.default = _default;
});