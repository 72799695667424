define("pesquisa-talentrh/models/cause", ["exports", "@ember-data/model", "ember-data-copyable", "ember-data/relationships"], function (_exports, _model, _emberDataCopyable, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend(_emberDataCopyable.default, {
    // Attributes
    description: (0, _model.attr)('string'),
    remodeId: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    // Associations
    company: (0, _relationships.belongsTo)('company')
  });
  _exports.default = _default;
});