define("pesquisa-talentrh/pods/survey/index/send/external-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8ZU0/zDj",
    "block": "{\"symbols\":[\"Card\",\"email\"],\"statements\":[[5,\"ui-card\",[[12,\"class\",\"border-top-0\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Informe para quais e-mails deseja enviar o questionário:\"],[9],[0,\"\\n\\n    \"],[5,\"power-select-multiple\",[[12,\"class\",\"survey-send-external__input\"]],[[\"@options\",\"@selected\",\"@placeholder\",\"@renderInPlace\",\"@searchEnabled\",\"@onChange\",\"@onKeydown\",\"@onClose\"],[[22,\"externalEmails\"],[22,\"externalEmails\"],\"Ex: email@email.com\",true,true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"externalEmails\"]]],null]],null],[28,\"action\",[[23,0,[]],\"addEmail\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null]]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/survey/index/send/external-email/template.hbs"
    }
  });
  _exports.default = _default;
});