define("pesquisa-talentrh/pods/add-answer/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    menuControl: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel() {
      Ember.set(this, 'menuControl.show', false);
    },
    model(params) {
      return this.modelTask.perform(params);
    },
    modelTask: (0, _emberConcurrency.task)(function* (params) {
      try {
        let quiz = yield this.store.findRecord('quiz', params.id);
        const user = this.store.peekRecord('user', this.session.user.id);
        let quizAnswer = this.store.createRecord('quiz-answer', {
          quiz,
          anonymous: quiz.anonymous,
          answers: quiz.sections,
          createdBy: user,
          user
        });
        if (quiz.anonymous) {
          yield quizAnswer.save();
        }
        if (quiz.quizType === 'demissional') {
          let causes = [];
          if (quiz.causes) {
            causes = quiz.causes.map(c => c.id);
          }
          const firedUsers = yield this.store.loadRecords('user', 'users/findFired', {
            quiz: quiz.id,
            causes
          });
          return (0, _emberConcurrency.hash)({
            quizAnswer,
            firedUsers
          });
        }
        return (0, _emberConcurrency.hash)({
          quizAnswer,
          firedUsers: []
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});