define("pesquisa-talentrh/pods/survey/form/section-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uXy7vcNh",
    "block": "{\"symbols\":[\"@sections\"],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-primary text-white\"],[10,\"title\",\"Nova questão\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"newQuestion\"],null]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus fa-lg\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-primary text-white ml-1\"],[10,\"title\",\"Nova seção\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"newSection\"],null]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fas fa-pause fa-rotate-90\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-primary text-white ml-1\"],[10,\"title\",\"Duplicar seção\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"cloneSection\"],null]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fas fa-clone\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gt\",[[23,1,[\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"btn btn-sm btn-primary text-white ml-1\"],[10,\"title\",\"Excluir seção\"],[11,\"onclick\",[28,\"perform\",[[24,[\"deleteSection\"]]],null]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fas fa-trash\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/survey/form/section-options/template.hbs"
    }
  });
  _exports.default = _default;
});