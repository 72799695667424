define("pesquisa-talentrh/pods/result/general/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "x3KF5hpM",
    "block": "{\"symbols\":[\"section\",\"sectionIndex\"],\"statements\":[[4,\"each\",[[24,[\"quiz\",\"sections\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 mb-3\"],[8],[0,\"\\n      \"],[5,\"result/general/section\",[],[[\"@quiz\",\"@section\"],[[22,\"quiz\"],[28,\"assign\",[[23,1,[]],[28,\"hash\",null,[[\"index\"],[[23,2,[]]]]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pesquisa-talentrh/pods/result/general/template.hbs"
    }
  });
  _exports.default = _default;
});