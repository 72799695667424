define("pesquisa-talentrh/pods/survey/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    admin: Ember.computed.alias('permission.accessProfile.admin'),
    allowManageSearches: Ember.computed.alias('permission.accessProfile.allowManageSearches'),
    allowCreateQuestionnaires: Ember.computed.alias('permission.accessProfile.allowCreateQuestionnaires'),
    beforeModel() {
      if (!this.admin && !this.allowManageSearches && !this.allowCreateQuestionnaires) {
        return this.router.transitionTo('errors.forbidden');
      }
    },
    model(_, transition) {
      let {
        quizType
      } = transition.to.queryParams;
      return (0, _emberConcurrency.hash)({
        quizType
      });
    }
  });
  _exports.default = _default;
});